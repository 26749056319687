import { useState, useEffect } from 'react';
import { TimerDisplType, TimerPropsType, TimerStateType, TimerDisplDefaults } from '../types/FitnessTimerTypes';

export default function useTimerDisplayLogic(props: { timerProps: TimerPropsType; timerState: TimerStateType }) {
  const [timerDispl, setTimerDispl] = useState<TimerDisplType>(TimerDisplDefaults);

  /*const timeBlocks = () => {
    let blocks = [];
    for(let i=1; i<timerProps.tabatas; )
    blocks.push({ title: 'Prepare', duration: timerProps.prepare*1000, color: '#FFFF00' });
    for(let j=1; i<timerProps.cycles; j++) {
      blocks.push({ title: 'Work', duration: timerProps.work*1000, color: '#00FF00' });
      blocks.push({ title: 'Rest', duration: timerProps.rest*1000, color: '#FF0000' });
    }
    blocks.push({ title: 'Work', duration: timerProps.work*1000, color: '#00FF00' });
    return 
  }*/

  const buildTimerDispl = () => {
    let prepDuration = props.timerProps.prepare * 1000;
    let workDuration = props.timerProps.work * 1000;
    let restDuration = props.timerProps.rest * 1000;
    let cycleDuration = workDuration + restDuration;
    let tabataDuration = prepDuration + props.timerProps.cycles * cycleDuration - restDuration;

    let tabataCount = Math.floor(props.timerState.clock / tabataDuration);
    let tabataClock = props.timerState.clock % tabataDuration;

    let inPrep = tabataClock < prepDuration;
    let cycleCount = inPrep ? 0 : Math.floor((tabataClock - prepDuration) / cycleDuration);
    let cycleClock = inPrep ? tabataClock : (tabataClock - prepDuration) % cycleDuration;

    let inWork = inPrep ? false : cycleClock < workDuration;
    let inRest = !(inPrep || inWork);

    let countdown = inPrep
      ? prepDuration - tabataClock
      : inWork
      ? workDuration - cycleClock
      : inRest
      ? cycleDuration - cycleClock
      : 0;

    let dispState = {
      tabatas: props.timerProps.tabatas - tabataCount,
      cycles: props.timerProps.cycles - cycleCount,
      countdown: countdown,
      state: inPrep ? 'Prepare' : inWork ? 'Work' : 'Rest',
      color: inPrep ? '#CCCC00' : inWork ? '#00FF00' : '#FF0000',
    };

    setTimerDispl(dispState);
  };

  useEffect(buildTimerDispl, [props.timerState.clock, props.timerProps]);

  return { timerDispl, setTimerDispl };
}
