import { useState } from 'react';
import { TimerPropsType, TimerPropsDefaults, TimerStateType, TimerStateDefaults } from '../types/FitnessTimerTypes';
import { Howl } from 'howler';
import useRoomState from '../../../hooks/useRoomState/useRoomState';

const sound = new Howl({ src: ['/beeps.webm', '/beeps.mp3'], preload: true });

export function useTimerLogic() {
  const roomState = useRoomState();
  const [timerProps, setTimerProps] = useState<TimerPropsType>(TimerPropsDefaults);
  const [timerState, setTimerState] = useState<TimerStateType>(TimerStateDefaults);

  const tickTimer = () => {
    setTimerState(prev => {
      let now = Date.now();
      let delta = prev.offset ? now - prev.offset : 0;
      let newClock = prev.clock + delta;
      return { ...prev, clock: newClock, offset: now };
    });
  };

  const timerSetProp = (prop: string, val: string | number) => {
    setTimerProps(prev => ({ ...prev, [prop]: val }));
  };

  const timerStart = () => {
    if (timerState.interval) return;
    if (roomState !== 'connected') {
      alert('Connect to a Room Before Starting the Timer.');
      return;
    }
    setTimerState(prev => ({ ...prev, offset: Date.now(), interval: window.setInterval(tickTimer, 250) }));
  };

  const timerStop = () => {
    if (!timerState.interval) return;
    window.clearInterval(timerState.interval);
    setTimerState(prev => ({ ...prev, interval: 0 }));
    if (sound.playing()) {
      sound.stop();
    }
  };

  const timerReset = () => {
    window.clearInterval(timerState.interval);
    setTimerState(TimerStateDefaults);
    if (sound.playing()) {
      sound.stop();
    }
  };

  return { timerProps, timerState, timerSetProp, timerStart, timerStop, timerReset };
}
