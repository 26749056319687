import React, { useState, useEffect } from 'react';
import Participant from '../Participant/Participant';
import { styled } from '@material-ui/core/styles';
import useParticipants from '../../hooks/useParticipants/useParticipants';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import useSelectedParticipant from '../VideoProvider/useSelectedParticipant/useSelectedParticipant';
import { RemoteParticipant } from 'twilio-video';

const Container = styled('aside')(({ theme }) => ({
  padding: '0.5em',
  overflowY: 'auto',
  [theme.breakpoints.down('xs')]: {
    overflowY: 'initial',
    overflowX: 'auto',
    padding: 0,
    display: 'flex',
  },
}));

const ScrollContainer = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('xs')]: {
    display: 'flex',
  },
}));

export default function ParticipantStrip() {
  const {
    room: { localParticipant },
  } = useVideoContext();
  const { participants } = useParticipants();
  const [hideLocal, setHideLocal] = useState<boolean>(false);
  const [filteredParticipants, setFilteredParticipants] = useState<RemoteParticipant[]>([]);
  const [selectedParticipant, setSelectedParticipant] = useSelectedParticipant();

  useEffect(() => {
    setFilteredParticipants(participants.filter(p => p.identity !== 'music'));
  }, [participants]);

  useEffect(() => {
    switch (localParticipant.identity) {
      case 'ig':
      case 'fb':
        setHideLocal(true);
        break;
      default:
        setHideLocal(false);
    }
  }, [localParticipant]);

  return (
    <Container>
      <ScrollContainer>
        {!hideLocal && (
          <Participant
            participant={localParticipant}
            isSelected={selectedParticipant === localParticipant}
            onClick={() => setSelectedParticipant(localParticipant)}
          />
        )}
        {filteredParticipants.map(participant => (
          <Participant
            key={participant.sid}
            participant={participant}
            isSelected={selectedParticipant === participant}
            onClick={() => setSelectedParticipant(participant)}
          />
        ))}
      </ScrollContainer>
    </Container>
  );
}
