import React, { useCallback, useEffect, useState } from 'react';
import useUserInfo from '../CosmicLogin/hooks/useUserInfo';
import { List, ListItem, makeStyles, Theme, createStyles } from '@material-ui/core';
import { ScheduleOccurrence } from '../ScheduleChooser/useCosmicSchedule';
import { useAppState } from '../../state';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import ScheduleListItem from '../ScheduleChooser/ScheduleListItem';
import BuyClassPack from '../Purchasing/BuyClassPack';
import Donate from '../Purchasing/Donate';
import { throwHTTPStatusErrs } from '../../state/useCosmicAuth/useCosmicAuth';
import AudioOutputList from '../../components/MenuBar/DeviceSelector/AudioOutputList/AudioOutputList';
import AudioInputList from '../../components/MenuBar/DeviceSelector/AudioInputList/AudioInputList';
import VideoInputList from '../../components/MenuBar/DeviceSelector/VideoInputList/VideoInputList';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      textAlign: 'center',
    },
    heading: {
      fontSize: '1.2em',
      width: '40em',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    listItemContainer: {
      marginTop: '1em',
      background: '#303030',
    },
    list: {
      margin: '2em auto',
      width: '100%',
      backgroundColor: theme.palette.background.default,
      '& .listItem': {
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: theme.palette.background.paper,
        },
        '&:active': {
          backgroundColor: 'rgba(255,255,255,0.2)',
        },
      },
    },
  })
);

const getFormData = (object: { [x: string]: any }) => {
  const formData = new FormData();
  Object.keys(object).forEach(key => formData.append(key, object[key]));
  return formData;
};

export default function UserClassCheckin({
  occ,
  clearRoom,
}: {
  occ: ScheduleOccurrence;
  clearRoom: () => void | undefined;
}) {
  const { customerData, customerStatus, refresh } = useUserInfo();
  const { getToken, user } = useAppState();
  const { connectOcc } = useVideoContext();
  const [hasReservation, setHasReservation] = useState<boolean>(false);
  const classes = useStyles();

  useEffect(() => {
    console.log('Refreshing Customer Data');
    console.log(customerData);
    refresh();
    console.log(customerData);
  }, [user]);

  useEffect(() => {
    if (!customerData) return;
    const data = {
      customer_id: customerData.id,
      classdef_id: occ.classdef.id,
      staff_id: occ.instructors[0].id,
      location_id: 3,
      starttime: occ.starttime,
    };
    fetch('https://cosmicfitclub.com/models/classdefs/reservations/exists', {
      method: 'POST',
      body: getFormData(data),
      credentials: 'include',
    })
      .then(throwHTTPStatusErrs)
      .then(json => {
        console.log(json);
        setHasReservation(json === true);
      });
  }, [customerData, occ]);

  const postReservation = useCallback(
    (trans_type: string, payment_id?: string) => {
      if (!customerData) return;
      const data = {
        customer_id: customerData.id,
        classdef_id: occ.classdef.id,
        staff_id: occ.instructors[0].id,
        location_id: 3,
        starttime: occ.starttime,
        transaction_type: trans_type,
        payment_id: payment_id,
      };
      fetch('https://cosmicfitclub.com/models/classdefs/reservation', {
        method: 'POST',
        body: getFormData(data),
        credentials: 'include',
      })
        .then(throwHTTPStatusErrs)
        .then(json => {
          console.log(json);
          getToken(customerData?.name, occ.roomStr).then(token => connectOcc(token, occ));
          return json;
        });
    },
    [customerData, occ]
  );

  const useMembership = () => {
    postReservation('membership');
  };

  const usePass = (ev: React.MouseEvent) => {
    console.log(ev);
    if (customerStatus && customerStatus.passes > 0) {
      postReservation('class_pass');
    }
  };

  const enterFree = () => {
    postReservation('free');
  };

  const enterRoom = () => {
    if (!customerData) return;
    getToken(customerData.name, occ.roomStr).then(token => connectOcc(token, occ));
  };

  const clearOcc = (occ: ScheduleOccurrence) => {
    if (clearRoom) clearRoom();
  };

  console.log(occ);

  if (!customerData) return null;
  if (!customerStatus) return null;

  return (
    <div className={classes.container}>
      <div className={classes.heading}>{customerData.name}, You are registering for:</div>
      <div className={classes.listItemContainer}>
        <ScheduleListItem key={occ.roomStr} occ={occ} handler={clearOcc} />
      </div>
      {!hasReservation && (
        <List className={classes.list}>
          {customerStatus.membership && (
            <ListItem onClick={useMembership} className="listItem" divider={true}>
              <div>Use Membership</div>
              <div>({customerStatus.membership.name})</div>
            </ListItem>
          )}
          <ListItem onClick={usePass} className="listItem" divider={true}>
            <div>Use Class Pass</div>
            <div>({customerStatus.passes} Remaining)</div>
            <BuyClassPack onComplete={refresh} />
          </ListItem>
          <ListItem className="listItem" divider={occ.allow_free}>
            <Donate occ={occ} onComplete={refresh} />
          </ListItem>
          {occ.allow_free && (
            <ListItem onClick={enterFree} className="listItem">
              Enter For Free
            </ListItem>
          )}
        </List>
      )}
      {hasReservation && (
        <List className={classes.list}>
          <ListItem onClick={enterRoom} className="listItem">
            You are already registered. Join Now!
          </ListItem>
        </List>
      )}
      <List className={classes.list}>
        <ListItem>
          <AudioInputList />
          <AudioOutputList />
        </ListItem>
        <ListItem>
          <VideoInputList />
        </ListItem>
      </List>
    </div>
  );
}
