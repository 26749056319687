import { styled, Typography } from '@material-ui/core';
import React, { CSSProperties } from 'react';
import theme from '../../theme';

const Tile = styled('div')({
  position: 'relative',
  display: 'flex',
  boxShadow: '0 0 5px white',
  background: 'rgba(30,30,30,0.8)',
  margin: '1em',
  textAlign: 'center',
  borderRadius: '2em',
  boxSizing: 'border-box',
  width: '15em',
  height: '15em',
  [theme.breakpoints.down('sm')]: {
    width: '15vw',
    height: '15vw',
  },
});

const TileImg = styled('img')({
  width: '100%',
  height: '100%',
  borderRadius: '2em',
});

const TileLines = styled('div')({
  position: 'absolute',
  borderRadius: '2em',
  backgroundColor: 'rgba(0,0,0,0.6)',
  bottom: '0',
  left: '0',
  right: '0',
});

interface CosmicTileProps {
  src: string;
  textLines?: string[];
  style?: CSSProperties;
  className?: string;
}

export function CosmicTile(props: CosmicTileProps) {
  return (
    <Tile style={props.style} className={props.className}>
      <TileImg src={props.src} />
      <TileLines>
        {props.textLines?.map(x => (
          <Typography>{x}</Typography>
        ))}
      </TileLines>
    </Tile>
  );
}
