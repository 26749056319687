import { useAppState, StateContextType } from '../../../state';
import { useEffect, useState, useCallback } from 'react';
import { CosmicUserType, throwHTTPStatusErrs } from '../../../state/useCosmicAuth/useCosmicAuth';

const corsOpts: { credentials: 'include'; mode: 'cors' } = { credentials: 'include', mode: 'cors' };

export interface PaymentSourceType {
  id: string;
  object: string;
  address_city: string;
  address_country: string;
  address_line1: string;
  address_line1_check: string;
  address_line2: string;
  address_state: string;
  address_zip: string;
  address_zip_check: string;
  brand: string;
  country: string;
  customer: string;
  cvc_check: string;
  dynamic_last4: string;
  exp_month: number;
  exp_year: number;
  fingerprint: string;
  funding: string;
  last4: string;
  metadata: {};
  name: string;
  tokenization_method: string;
}

export interface CustomerDataType {
  id: number;
  name: string;
  phone: string;
  email: string;
  stripe_id: string;
  wallet_id: number;
  address: string;
  created_on: string;
  payment_sources: PaymentSourceType[];
}

export interface SubscriptionType {
  id: number;
  name: string;
  month_price: number;
  renews_as: number;
  term_months: number;
  presale: boolean;
  child: boolean;
  stripe_id: string;
  hidden: boolean;
  free: boolean;
}

export interface CustomerStatusType {
  membership: SubscriptionType;
  passes: number;
}

export default function useUserInfo() {
  const { user, signOut } = useAppState() as { user: CosmicUserType; signOut: () => Promise<void> };
  const [customerData, setCustomerData] = useState<CustomerDataType | null>(null);
  const [customerStatus, setCustomerStatus] = useState<CustomerStatusType | null>(null);

  const fetchCustomer = (cust_id: number) => {
    return fetch(`https://cosmicfitclub.com/models/customers/${cust_id}`, corsOpts)
      .then(throwHTTPStatusErrs)
      .catch(err => {
        signOut && signOut();
      });
  };

  const fetchCustyStatus = (cust_id: number) => {
    return fetch(`https://cosmicfitclub.com/models/customers/${cust_id}/status`, corsOpts)
      .then(throwHTTPStatusErrs)
      .catch(err => {
        signOut && signOut();
      });
  };

  useEffect(() => {
    fetchCustomer(user?.customer_id).then(data => setCustomerData(data));
    fetchCustyStatus(user?.customer_id).then(data => setCustomerStatus(data));
  }, [user]);

  const refresh = useCallback(() => {
    if (!user) return;
    fetchCustomer(user.customer_id).then(data => setCustomerData(data));
    fetchCustyStatus(user.customer_id).then(data => setCustomerStatus(data));
  }, [user]);

  return { customerData: customerData, customerStatus: customerStatus, refresh: refresh };
}
