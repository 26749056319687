import React, { FormEvent, useEffect, useState, ChangeEvent, MouseEvent } from 'react';
import { Grid, makeStyles, Paper, TextField, Link, Typography, Button } from '@material-ui/core';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import backgroundImage from './background-blu.jpg';

import donutPhoneImg from './donut-phone.png';
import { useAppState } from '../../state';
import useParticipantNetworkQualityLevel from '../../hooks/useParticipantNetworkQualityLevel/useParticipantNetworkQualityLevel';

const useStyles = makeStyles({
  container: {},
  paper: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '2em',
    boxShadow: '0 0 5px white',
    background: 'rgba(30,30,30,0.8)',
    color: 'rgba(255,255,255,0.8)',
  },
  donutImg: {
    width: '25%',
    padding: '2.4em 0 2.1em',
  },
  input: {
    color: 'white',
  },
  hidden: {
    display: 'none',
  },
  button: {
    color: 'black',
    background: 'white',
    margin: '0.8em 0 0.7em',
    textTransform: 'none',
  },
  errorMessage: {
    color: 'red',
    display: 'flex',
    alignItems: 'center',
    margin: '1em 0 0.2em',
    '& svg': {
      marginRight: '0.4em',
    },
  },
  pointer: { cursor: 'pointer' },
});

export default function CosmicLoginForm() {
  const classes = useStyles();

  const { signIn, user, checkEmail, createAccount, sendResetLink, resetPassword } = useAppState();

  const [email, setEmail] = useState<string>('');
  const [fullName, setFullName] = useState<string>('');
  const [passcode, setPasscode] = useState<string>('');
  const [hasAccount, setHasAccount] = useState<boolean>(false);
  const [authError, setAuthError] = useState<string | null>(null);
  const [resetMode, setResetMode] = useState<boolean>(false);
  const [resetTok, setResetTok] = useState<string>('');

  useEffect(() => {
    checkEmail?.(email).then(x => setHasAccount(!!x));
  }, [email, checkEmail]);

  const login = () => {
    setAuthError(null);
    signIn?.(email, passcode).catch((err: Error) => {
      console.log(err.message);
      setAuthError(err.message);
    });
  };

  const create_account = () => {
    setAuthError(null);
    createAccount?.(email, fullName, passcode).catch((err: Error) => setAuthError(err.message));
  };

  const reset = () => {
    setAuthError(null);
    sendResetLink?.(email)
      .then(() => {
        setResetMode(true);
      })
      .catch((err: Error) => setAuthError(err.message));
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    hasAccount ? login() : create_account();
  };

  const submitNewPassword = () => {
    if (!resetPassword) return;
    resetPassword(resetTok, passcode)
      //.then( () => setResetMode(false))
      .catch((err: Error) => setAuthError(err.message));
  };

  useEffect(() => {
    console.log('My Auth Error: ' + authError);
  }, [authError]);

  return (
    <Grid container justify="center" alignItems="center" className={classes.container}>
      <Paper className={classes.paper} elevation={6}>
        <img className={classes.donutImg} src={donutPhoneImg} alt="Donut on the phone"></img>
        <form onSubmit={handleSubmit}>
          {resetMode && (
            <Grid container alignItems="center" direction="column">
              <h3>Check Your E-Mail for a Message from Donut.</h3>
              <TextField
                label="Reset Token"
                name="email"
                className={classes.input}
                inputProps={{
                  autoComplete: 'off',
                }}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setResetTok(e.target.value)}
              />
              <TextField
                className={classes.input}
                name="password"
                label="Choose A Password"
                inputProps={{
                  autoComplete: 'off',
                }}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setPasscode(e.target.value)}
                type="password"
              />
              <div>
                {authError && (
                  <Typography variant="caption" className={classes.errorMessage}>
                    <ErrorOutlineIcon />
                    <span>{authError}</span>
                  </Typography>
                )}
              </div>
              <Link className={classes.pointer} onClick={(event: MouseEvent) => reset()}>
                Send a New Token
              </Link>
              <Button
                variant="contained"
                className={classes.button}
                onClick={submitNewPassword}
                disabled={!passcode.length || !resetTok.length}
              >
                Reset Password
              </Button>
            </Grid>
          )}
          {!resetMode && (
            <Grid container alignItems="center" direction="column">
              <h3>Enter your Email to Login or to Create an Account</h3>
              <TextField
                label="Email"
                name="email"
                className={classes.input}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
              />
              <TextField
                className={classes.input}
                name="password"
                label={hasAccount ? 'Password' : 'Choose A Password'}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setPasscode(e.target.value)}
                type="password"
              />
              <TextField
                label="Full Name"
                name="name"
                value={fullName}
                className={hasAccount ? classes.hidden : classes.input}
                onChange={(e: ChangeEvent<HTMLInputElement>) => setFullName(e.target.value)}
              />
              {hasAccount && (
                <Link className={classes.pointer} onClick={(event: MouseEvent) => reset()}>
                  Reset Password
                </Link>
              )}
              <div>
                {authError && (
                  <Typography variant="caption" className={classes.errorMessage}>
                    <ErrorOutlineIcon />
                    <span>{authError}</span>
                  </Typography>
                )}
              </div>
              <Button variant="contained" className={classes.button} type="submit" disabled={!passcode.length}>
                {hasAccount && 'Log In'}
                {!hasAccount && 'Create Account'}
              </Button>
            </Grid>
          )}
        </form>
      </Paper>
    </Grid>
  );
}
