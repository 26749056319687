import React, { useState } from 'react';
import { CardElement, Elements, useStripe, useElements } from '@stripe/react-stripe-js';
import { makeStyles, Theme, createStyles, FormControlLabel, Checkbox } from '@material-ui/core';
import { PaymentSource } from '../SavedUserCards/SavedUserCards';
import { PaymentMethod } from '@stripe/stripe-js';
import { CosmicUserType } from '../../../state/useCosmicAuth/useCosmicAuth';
import { useAppState } from '../../../state';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      display: 'flex',
      flexDirection: 'column',
      padding: '0.5em',
      width: '100%',
    },
    card: {
      width: '100%',
      padding: '1em',
      boxShadow: '0 0 0.5em black inset',
      borderRadius: '1em',
      background: 'white',
      marginBottom: '0.5em',
    },
    checkout: {
      width: '100%',
      fontSize: '1em',
      lineHeight: '2em',
      cursor: 'pointer',
      padding: '0.5em',
      marginBottom: '0.5em',
      borderRadius: '1em',
      boxShadow: '0 0 0.5em black inset',
      border: 'none',
    },
  })
);

const CARD_ELEMENT_OPTIONS = {
  hidePostalCode: true,
  style: {
    base: {
      fontSize: '1em',
      width: '100%',
    },
  },
};

const saveCard = (custy_id: number, token: string) => {
  return fetch(`https://cosmicfitclub.com/checkout/save_card`, {
    method: 'POST',
    mode: 'cors',
    credentials: 'include',
    body: JSON.stringify({
      token: token,
      customer_id: custy_id,
    }),
    headers: new Headers({ 'Content-type': 'application/json' }),
  }).then(resp => console.log(resp.json()));
};

export default function NewStripeCheckout(attrib: {
  onCard: (src: PaymentMethod | PaymentSource, save: boolean | undefined) => void;
  onCardSaved: () => void;
}) {
  const stripe = useStripe();
  const elements = useElements();
  const classes = useStyles();
  const { user } = useAppState();
  const [save, setSave] = useState<boolean>(false);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!stripe) return;
    if (!elements) return;
    setSave(false);
    let card = elements.getElement(CardElement);
    if (!card) return;
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: card,
    });
    paymentMethod && attrib.onCard(paymentMethod, save);
  };

  const handleSaveCard = async () => {
    if (!stripe) return;
    if (!elements) return;
    let card = elements.getElement(CardElement);
    if (!card) return;
    const { token, error } = await stripe.createToken(card);
    if (token) {
      saveCard((user as CosmicUserType).customer_id, token.id).then(resp => {
        console.log('calling on card saved!!!');
        attrib.onCardSaved();
      });
    } else {
      alert(error ? error.message : 'Unknown Error Saving Card');
    }
  };

  return (
    <form className={classes.form} onSubmit={handleSubmit}>
      <h3>Enter A New Card:</h3>
      <div className={classes.card}>
        <CardElement options={CARD_ELEMENT_OPTIONS} />
      </div>
      {false && (
        <FormControlLabel
          control={<Checkbox checked={save} onChange={e => setSave(e.target.checked)} />}
          label="Save This Card"
        />
      )}
      <button className={classes.checkout} type="button" disabled={!stripe} onClick={() => handleSaveCard()}>
        Add it to my Saved Cards
      </button>
      <button className={classes.checkout} type="submit" disabled={!stripe}>
        Use it to Pay Now
      </button>
    </form>
  );
}
