import { useContext } from 'react';
import { VideoContext } from '../../components/VideoProvider';
import { VideoContext as VideoContextNoPub } from '../../components/VideoProvider/VideoProviderNoPub';

export default function useVideoContext() {
  const context = useContext(VideoContext);
  const contextNoPub = useContext(VideoContextNoPub);

  if (!(context || contextNoPub)) {
    throw new Error('useVideoContext must be used within a VideoProvider');
  }

  return context || contextNoPub;
}
