import React, { useState, useEffect } from 'react';
import { Modal, makeStyles, createStyles, Theme, CircularProgress, Divider } from '@material-ui/core';
import { loadStripe, PaymentMethod, PaymentIntent } from '@stripe/stripe-js';
import { Elements, useStripe } from '@stripe/react-stripe-js';
import NewStripeCheckout from './StripeCheckout/NewStripeCheckout';
import SavedUserCards, { PaymentSource } from './SavedUserCards/SavedUserCards';
// @ts-ignore
import CurrencyTextField from '@unicef/material-ui-currency-textfield';
import { ScheduleOccurrence } from '../ScheduleChooser/useCosmicSchedule';

const STRIPE_PUBLIC = process.env.REACT_APP_STRIPE_PUBLIC as string;
const stripePromise = loadStripe(STRIPE_PUBLIC);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    button: {
      cursor: 'pointer',
      width: '100%',
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    container: {
      background: 'rgb(50,50,50)',
      borderRadius: '2em',
      boxShadow: '0 0 1em grey inset',
      padding: '1.5em',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    progressMargin: {
      margin: '5vw 10vw',
    },
    currency: {
      width: '100%',
    },
    padding: {
      padding: '0.5em',
    },
    amountWrapper: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '1em',
    },
  })
);

interface ClassPackage {
  id: number;
  name: string;
  num_passes: number;
  pass_price: number;
  presale: boolean;
  stripe_id: string;
  available: boolean;
  available_at_desk: boolean;
}

const getPaymentIntent = (amount: number, description: string) => {
  return fetch(`https://cosmicfitclub.com/checkout/create_intent`, {
    method: 'POST',
    mode: 'cors',
    credentials: 'include',
    body: JSON.stringify({ amount: amount, description: description }),
    headers: new Headers({ 'Content-type': 'application/json' }),
  }).then(resp => resp.json());
};

const updatePaymentIntent = (amount: number, description: string, intent: PaymentIntent) => {
  return fetch(`https://cosmicfitclub.com/checkout/update_intent`, {
    method: 'POST',
    mode: 'cors',
    credentials: 'include',
    body: JSON.stringify({ amount: amount, description: description, intent_id: intent.id }),
    headers: new Headers({ 'Content-type': 'application/json' }),
  }).then(resp => resp.json());
};

const donate = (intent_id: string, occ: ScheduleOccurrence) => {
  return fetch(`https://cosmicfitclub.com/checkout/donate/intent`, {
    method: 'POST',
    mode: 'cors',
    credentials: 'include',
    body: JSON.stringify({
      intent_id: intent_id,
      classdef_id: occ.classdef.id,
      location_id: 3,
      staff_id: occ.instructors[0].id,
      starttime: occ.starttime,
    }),
    headers: new Headers({ 'Content-type': 'application/json' }),
  }).then(resp => resp.json());
};

export default function Donate(attr: { occ: ScheduleOccurrence; onComplete: () => void }) {
  return (
    <Elements stripe={stripePromise}>
      <DonateInternal occ={attr.occ} onComplete={attr.onComplete} />
    </Elements>
  );
}

function DonateInternal(attr: { occ: ScheduleOccurrence; onComplete: () => void }) {
  const [open, setOpen] = useState<boolean>(false);
  const [intent, setIntent] = useState<PaymentIntent | null>(null);
  const [value, setValue] = useState<number>(0);
  const [busy, setBusy] = useState<boolean>(false);
  const [refreshSaved, setRefreshSaved] = useState<boolean>(false);

  const stripe = useStripe();
  const classes = useStyles();

  const checkout = (src: PaymentMethod | PaymentSource, save: boolean | undefined) => {
    if (!intent) return;
    if (!intent.client_secret) return;
    setBusy(true);
    stripe?.confirmCardPayment(intent.client_secret, { payment_method: src.id }).then(val => {
      if (!val.paymentIntent) {
        if (val.error) {
          alert('Transaction Failed: ' + val.error.message);
        }
        setBusy(false);
        return;
      }
      setIntent(val.paymentIntent);
      donate(val.paymentIntent.id, attr.occ).then(val => {
        setOpen(false);
        setBusy(false);
        attr.onComplete();
      });
    });
  };

  const handleClick = () => {
    setIntent(null);
    setValue(20);
    setOpen(true);
  };

  useEffect(() => {
    if (intent) {
      updatePaymentIntent(value * 100, 'donation for ' + attr.occ.roomStr, intent).then(val => setIntent(val));
    } else {
      getPaymentIntent(value * 100, 'donation for ' + attr.occ.roomStr).then(val => setIntent(val));
    }
  }, [value, attr.occ.roomStr]);

  return (
    <div className={classes.root} onClick={ev => ev.stopPropagation()}>
      <div className={classes.button} onClickCapture={handleClick}>
        Donate Now
      </div>
      <Modal open={open} onClose={() => setOpen(false)} className={classes.modal}>
        <>
          {busy && (
            <div className={classes.container}>
              <h3>Processing... Please Wait</h3>
              <CircularProgress size="8vw" classes={{ root: classes.progressMargin }} />
            </div>
          )}
          {!busy && (
            <div className={classes.container}>
              <div className={classes.padding}>
                <h3>Amount to Donate</h3>
                <div className={classes.amountWrapper}>
                  <CurrencyTextField
                    label="Amount"
                    className={classes.currency}
                    value={value}
                    decimalCharacter="."
                    digitGroupSeparator=","
                    onChange={(_event: any, value: number) => setValue(value)}
                  />
                </div>
              </div>
              <Divider />
              <SavedUserCards
                onCard={checkout}
                refresh={refreshSaved}
                onBusy={value => {
                  setBusy(value);
                }}
              />
              <Divider />
              <NewStripeCheckout
                onCard={checkout}
                onCardSaved={() => {
                  console.log('on Card Saved!');
                  setRefreshSaved(!refreshSaved);
                }}
              />
            </div>
          )}
        </>
      </Modal>
    </div>
  );
}
