import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    donateForm: {
      display: 'inline-block',
      padding: '0.25em 0 0 2em',
      verticalAlign: 'middle',
    },
    noBorder: {
      border: 0,
    },
  })
);

export default function PayPalButton() {
  const classes = useStyles();
  return (
    <form className={classes.donateForm} action="https://www.paypal.com/cgi-bin/webscr" method="post" target="_blank">
      <input type="hidden" name="cmd" value="_donations" />
      <input type="hidden" name="business" value="EKSH9VSRN5WNA" />
      <input type="hidden" name="currency_code" value="USD" />
      <input
        className={classes.noBorder}
        type="image"
        src="https://www.paypalobjects.com/en_US/i/btn/btn_donate_SM.gif"
        name="submit"
        title="PayPal - The safer, easier way to pay online!"
        alt="Donate with PayPal button"
      />
      <img
        alt=""
        className={classes.noBorder}
        src="https://www.paypal.com/en_US/i/scr/pixel.gif"
        width="1"
        height="1"
      />
    </form>
  );
}
