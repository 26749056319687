import React from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';

import ToggleFullscreenButton from '../../components/MenuBar/ToggleFullScreenButton/ToggleFullScreenButton';
import Menu from './Menu/Menu';
import FlipCameraButton from '../../components/MenuBar/FlipCameraButton/FlipCameraButton';
import { DeviceSelector } from '../../components/MenuBar/DeviceSelector/DeviceSelector';

import useVideoContext from '../../hooks/useVideoContext/useVideoContext';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    logo: {
      height: '65%',
      position: 'absolute',
      left: '0.5em',
    },
    container: {
      backgroundColor: theme.palette.background.default,
    },
    toolbar: {},
    rightButtonContainer: {
      position: 'absolute',
      right: 0,
      left: 'auto',
      top: 0,
      bottom: 0,
      display: 'flex',
      alignItems: 'center',
      marginLeft: 'auto',
      '& button': {
        padding: '8px',
      },
    },
    displayName: {
      margin: '1.1em 0.6em',
      minWidth: '200px',
      fontWeight: 600,
    },
    donateMargins: {
      margin: '0 1em',
    },
    inlineBlock: {
      display: 'inline-block',
      fontSize: 'min(1em,3vw)',
    },
  })
);

export default function MenuBar(props: { onLayout: (l: string) => void }) {
  const classes = useStyles();

  const { room, occurrence } = useVideoContext();

  return (
    <AppBar className={classes.container} position="static">
      <Toolbar className={classes.toolbar}>
        <img src="logo.png" alt="logo" className={classes.logo} />

        <div className={classes.rightButtonContainer}>
          <FlipCameraButton />
          <DeviceSelector />
          <ToggleFullscreenButton />
          <Menu onLayout={l => props.onLayout(l)} />
        </div>
      </Toolbar>
    </AppBar>
  );
}
