import { useState } from 'react';
import { LocalDataTrack } from 'twilio-video';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import { TimerDisplType } from '../types/FitnessTimerTypes';

export default function useTimerBroadcast() {
  const {
    room: { localParticipant },
  } = useVideoContext();
  const [localTrack, setLocalTrack] = useState<LocalDataTrack | null>(null);

  const startTimerBroadcast = () => {
    let dataTrack = new LocalDataTrack();
    setLocalTrack(dataTrack);
    localParticipant.publishTrack(dataTrack).then(localTrackPublication => {
      console.log(`Timer Track ${dataTrack.name} was published with SID ${localTrackPublication.trackSid}`);
    });
  };

  const stopTimerBroadcast = () => {
    if (!localTrack) return;
    console.log('unpublishing timer track');
    localParticipant.unpublishTrack(localTrack);
    setLocalTrack(null);
  };

  const sendTimerDispl = (val: TimerDisplType) => {
    localTrack?.send(JSON.stringify(val));
  };

  return { startTimerBroadcast, stopTimerBroadcast, sendTimerDispl };
}
