import { ScheduleOccurrence } from './useCosmicSchedule';
import { useEffect, useState } from 'react';
import React from 'react';
import { Divider, ListSubheader } from '@material-ui/core';
import ScheduleListItem from './ScheduleListItem';

export function ScheduleListHeader({ title }: { title: string }) {
  return (
    <>
      <ListSubheader style={{ backgroundColor: 'rgb(30,30,30)' }}>{title}</ListSubheader>
      <Divider />
    </>
  );
}

interface SchedListGroupProps {
  title: string;
  countdown?: boolean;
  arr: ScheduleOccurrence | ScheduleOccurrence[] | undefined | null;
  handler: (occ: ScheduleOccurrence) => void;
}

export default function ScheduleListGroup({ title, arr, countdown, handler }: SchedListGroupProps) {
  const [time, setTime] = useState<number>(Date.now());

  useEffect(() => {
    if (!countdown) return;
    const interval = setInterval(() => {
      setTime(Date.now());
    }, 1000);
    return () => clearInterval(interval);
  }, [countdown]);

  if (!arr) return null;
  arr = Array.isArray(arr) ? arr : [arr];
  if (arr.length === 0) return null;

  return (
    <>
      <ScheduleListHeader title={title} />
      {arr
        .map((occ, idx) => {
          if (!countdown) return <ScheduleListItem key={occ.roomStr} occ={occ} handler={handler} />;
          let start = Date.parse(occ.starttime);
          return <ScheduleListItem key={occ.roomStr} occ={occ} time={start - time} handler={handler} />;
        })
        .reduce((accu: JSX.Element[], elem: JSX.Element) => {
          return accu.length === 0 ? [elem] : [...accu, <Divider key={accu.length} />, elem];
        }, [])}
    </>
  );
}
