import React, { useEffect, useState } from 'react';
import {
  createStyles,
  makeStyles,
  Theme,
  List,
  Divider,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Typography,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import useCosmicSchedule, { ScheduleOccurrence } from './useCosmicSchedule';
import ScheduleListGroup, { ScheduleListHeader } from './ScheduleListGroup';
import moment from 'moment';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    list: {
      paddingTop: '0',
      paddingBottom: '0',
      maxHeight: '65vh',
      overflowY: 'auto',
      width: '50em',
      backgroundColor: theme.palette.background.default,
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        maxHeight: 'none',
      },
      '& .listItem': {
        position: 'relative',
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: theme.palette.background.paper,
        },
        '&:active': {
          backgroundColor: 'rgba(255,255,255,0.2)',
        },
      },
    },
    seeMore: {
      display: 'block',
      padding: 0,
    },
    expansion: {
      '&.Mui-expanded': {
        margin: 0,
      },
    },
    expansion_summary: {
      '& div.Mui-expanded': {
        margin: '12px 0',
      },
    },
  })
);

export default function ScheduleChooser2({ onChoice }: { onChoice: (l: ScheduleOccurrence) => void | undefined }) {
  const classes = useStyles();
  const history = useHistory();

  const { schedule } = useCosmicSchedule();
  const [roomName, setRoomName] = useState<string>('');
  const [expanded, setExpanded] = useState<boolean>(false);
  const [noClassesLeft, setNoClassesLeft] = useState<boolean>(false);

  useEffect(() => {
    if (!schedule) return;
    setNoClassesLeft(schedule.happening_now.length == 0 && schedule.later_today.length == 0);
  }, [schedule]);

  const handleRoomChange = (occ: ScheduleOccurrence) => {
    console.log(occ.roomStr);
    setRoomName(occ.roomStr);
    onChoice(occ);
  };

  const viewClassDetails = (occ: ScheduleOccurrence) => {
    history.push('/class?id=' + occ.classdef.id);
  };

  if (!schedule) return null;

  return (
    <div>
      <h3>Video Class Schedule</h3>
      <List className={classes.list}>
        <Divider />
        {schedule.earlier_today.length > 0 && (
          <ScheduleListGroup title="Earlier Today" arr={schedule.earlier_today} handler={handleRoomChange} />
        )}
        {schedule.earlier_today.length > 0 && <Divider />}
        {schedule.happening_now.length > 0 && (
          <ScheduleListGroup
            title="Happening Now"
            arr={schedule.happening_now}
            countdown={true}
            handler={handleRoomChange}
          />
        )}
        {schedule.happening_now.length > 0 && <Divider />}
        {schedule.later_today.length > 0 && (
          <ScheduleListGroup
            title="Later Today"
            arr={schedule.later_today}
            countdown={true}
            handler={handleRoomChange}
          />
        )}
        {schedule.later_today.length > 0 && <Divider />}
        <ExpansionPanel
          className={classes.expansion}
          expanded={expanded}
          onChange={() => {
            setExpanded(!expanded);
          }}
        >
          <ExpansionPanelSummary className={classes.expansion_summary} expandIcon={<ExpandMoreIcon />}>
            <Typography>{expanded ? 'Show Less' : 'Later This Week'}</Typography>
          </ExpansionPanelSummary>
          <ExpansionPanelDetails className={classes.seeMore}>
            {Object.entries(schedule.later_this_week).map(val => (
              <ScheduleListGroup
                key={moment(val[0]).format('ddd MM/DD')}
                title={moment(val[0]).format('ddd MM/DD')}
                arr={val[1]}
                handler={viewClassDetails}
              />
            ))}
          </ExpansionPanelDetails>
        </ExpansionPanel>
      </List>
    </div>
  );
}
