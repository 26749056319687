import MainParticipantInfo from '../../../components/MainParticipantInfo/MainParticipantInfo';
import MainParticipantTracks from '../ParticipantTracks/MainParticipantTracks';
import React from 'react';
import useMainSpeaker from '../../../hooks/useMainSpeaker/useMainSpeaker';

export default function MainParticipant() {
  const mainParticipant = useMainSpeaker();
  return (
    /* For FBView audio is not disabled  */
    <MainParticipantInfo participant={mainParticipant}>
      <MainParticipantTracks
        participant={mainParticipant}
        disableAudio={false}
        enableScreenShare
        videoPriority="high"
      />
    </MainParticipantInfo>
  );
}
