import React from 'react';
import useTrack from '../../../hooks/useTrack/useTrack';
import AudioTrack from '../../../components/AudioTrack/AudioTrack';
import MainVideoTrack from '../VideoTrack/MainVideoTrack';

import { IVideoTrack } from '../../../types';
import {
  AudioTrack as IAudioTrack,
  LocalTrackPublication,
  Participant,
  RemoteTrackPublication,
  Track,
} from 'twilio-video';

interface PublicationProps {
  publication: LocalTrackPublication | RemoteTrackPublication;
  participant: Participant;
  isLocal: boolean;
  disableAudio?: boolean;
  videoPriority?: Track.Priority | null;
}

export default function MainPublication({
  publication,
  participant,
  isLocal,
  disableAudio,
  videoPriority,
}: PublicationProps) {
  const track = useTrack(publication);

  if (!track) return null;

  console.log('Rendering Track' + track.kind + ' ' + disableAudio);

  switch (track.kind) {
    case 'video':
      return (
        <MainVideoTrack
          track={track as IVideoTrack}
          priority={videoPriority}
          isLocal={track.name.includes('camera') && isLocal}
        />
      );
    case 'audio':
      return disableAudio ? null : <AudioTrack track={track as IAudioTrack} participant={participant} />;
    default:
      return null;
  }
}
