import React from 'react';
import ClassDetailView from './ClassDetailView';
import { useQueryParam, NumberParam } from 'use-query-params';
import { ThemeProvider, Paper, makeStyles } from '@material-ui/core';
import theme from '../../theme';
import MenuBarNoVid from '../MenuBar/MenuBarNoVid';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
    },
  },
  paper: {
    padding: '1em',
    borderRadius: '0.5em',
    background: 'rgba(0,0,0,0.7)',
    height: 'min-content',
  },
});

export default function ClassPage() {
  const [id, setId] = useQueryParam('id', NumberParam);
  const classes = useStyles();

  return (
    <ThemeProvider theme={theme}>
      <MenuBarNoVid />
      <div className={classes.container}>
        <Paper className={classes.paper}>
          <ClassDetailView id={id || 0} />
        </Paper>
      </div>
    </ThemeProvider>
  );
}
