import React, { useState, useEffect } from 'react';
import { ThemeProvider, Paper, makeStyles } from '@material-ui/core';
import theme from '../CosmicTheme';
import { useAppState } from '../../state';
import MenuBar from '../MenuBar/MenuBar';
import NewStripeCheckout from '../Purchasing/StripeCheckout/NewStripeCheckout';
import SavedUserCards from '../Purchasing/SavedUserCards/SavedUserCards';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import BraintreeCheckout from '../Purchasing/BraintreeCheckout/BraintreeCheckout';
require('dotenv').config();

const STRIPE_PUBLIC = process.env.REACT_APP_STRIPE_PUBLIC as string;
const stripePromise = loadStripe(STRIPE_PUBLIC);

const useStyles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'flex-start',
      paddingTop: '2em',
    },
  },
  paper: {
    padding: '1.5em',
    borderRadius: '0.5em',
    background: 'rgba(0,0,0,0.7)',
    height: 'min-content',
    [theme.breakpoints.down('sm')]: {
      padding: 0,
      width: '100%',
    },
  },
});

export default function PurchasePage() {
  const { user } = useAppState();
  const classes = useStyles();
  const [refreshSaved, setRefreshSaved] = useState<boolean>(false);

  return (
    <ThemeProvider theme={theme}>
      <>
        <MenuBar
          onLayout={(l: string) => {
            return;
          }}
        />
        <div className={classes.container}>
          <Paper className={classes.paper}>
            <SavedUserCards onCard={card => {}} refresh={refreshSaved} onBusy={value => {}} />
            <Elements stripe={stripePromise}>
              <NewStripeCheckout
                onCard={card => {}}
                onCardSaved={() => {
                  setRefreshSaved(!refreshSaved);
                }}
              />
            </Elements>
            <BraintreeCheckout />
          </Paper>
        </div>
      </>
    </ThemeProvider>
  );
}
