import React from 'react';
import { TimerPropsType } from '../types/FitnessTimerTypes';
import { TextField, makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  container: {
    position: 'relative',
    margin: 'auto',
  },
  full_container: {
    margin: '2em 0',
  },
  full_width: {
    width: '100%',
  },
  half_container: {
    display: 'flex',
    margin: '2em 0',
  },
  half_width: {
    flex: 1,
  },
});

export default function FitnessTimerProps(props: {
  timerProps: TimerPropsType;
  onChange: (prop: string, val: string | number) => void;
}) {
  const classes = useStyles();

  const updateProps = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.onChange(event.target.name, event.target.value);
  };

  return (
    <div className={classes.container}>
      <div className={classes.full_container}>
        <TextField
          InputProps={{ style: { fontSize: '2.5em' } }}
          InputLabelProps={{ style: { fontSize: '1.5em' } }}
          className={classes.full_width}
          label="Prepare"
          name="prepare"
          type="number"
          onChange={updateProps}
          value={props.timerProps.prepare}
        />
      </div>
      <div className={classes.full_container}>
        <TextField
          InputProps={{ style: { fontSize: '2.5em' } }}
          InputLabelProps={{ style: { fontSize: '1.5em' } }}
          className={classes.full_width}
          label="Work"
          name="work"
          type="number"
          onChange={updateProps}
          value={props.timerProps.work}
        />
      </div>
      <div className={classes.full_container}>
        <TextField
          InputProps={{ style: { fontSize: '2.5em' } }}
          InputLabelProps={{ style: { fontSize: '1.5em' } }}
          className={classes.full_width}
          label="Rest"
          name="rest"
          type="number"
          onChange={updateProps}
          value={props.timerProps.rest}
        />
      </div>
      <div className={classes.half_container}>
        <TextField
          InputProps={{ style: { fontSize: '3em' } }}
          InputLabelProps={{ style: { fontSize: '2em' } }}
          className={classes.half_width}
          label="Cycles"
          name="cycles"
          type="number"
          onChange={updateProps}
          value={props.timerProps.cycles}
        />
        <TextField
          InputProps={{ style: { fontSize: '3em' } }}
          InputLabelProps={{ style: { fontSize: '2em' } }}
          className={classes.half_width}
          label="Tabatas"
          name="tabatas"
          type="number"
          onChange={updateProps}
          value={props.timerProps.tabatas}
        />
      </div>
    </div>
  );
}
