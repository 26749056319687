import React, { useState, useRef, useCallback } from 'react';
import AboutDialog from '../AboutDialog/AboutDialog';
import IconButton from '@material-ui/core/IconButton';
import MenuContainer from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreIcon from '@material-ui/icons/MoreVert';
import UserAvatar from '../UserAvatar/UserAvatar';

import { useAppState } from '../../../state';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';

export default function Menu(props: { onLayout: (l: string) => void }) {
  const { user, signOut } = useAppState();
  const { room, localTracks } = useVideoContext();

  const [aboutOpen, setAboutOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  const anchorRef = useRef<HTMLDivElement>(null);

  const handleSignOut = useCallback(() => {
    room.disconnect?.();
    localTracks.forEach(track => track.stop());
    signOut?.();
  }, [room.disconnect, localTracks, signOut]);

  return (
    <div ref={anchorRef}>
      <IconButton color="inherit" onClick={() => setMenuOpen(state => !state)}>
        {user ? <UserAvatar user={user} /> : <MoreIcon />}
      </IconButton>
      <MenuContainer open={menuOpen} onClose={() => setMenuOpen(state => !state)} anchorEl={anchorRef.current}>
        {user?.displayName && <MenuItem disabled>{user.displayName}</MenuItem>}
        <MenuItem onClick={() => setAboutOpen(true)}>About</MenuItem>
        <MenuItem
          onClick={() => {
            props.onLayout('default');
            setMenuOpen(false);
          }}
        >
          Default Layout
        </MenuItem>
        <MenuItem
          onClick={() => {
            props.onLayout('grid');
            setMenuOpen(false);
          }}
        >
          Grid Layout
        </MenuItem>
        <MenuItem
          onClick={() => {
            window.open('/music', '_blank');
          }}
        >
          Music
        </MenuItem>
        <MenuItem
          onClick={() => {
            window.open('/timer', '_blank');
          }}
        >
          Timer
        </MenuItem>
        <MenuItem
          onClick={() => {
            Array.from(document.getElementsByTagName('audio')).forEach(function(x) {
              x.setAttribute('controls', 'true');
            });
            Array.from(document.getElementsByClassName('audioContainer')).forEach(function(x) {
              x.setAttribute('style', 'display:inline-block');
            });
            setMenuOpen(false);
          }}
        >
          Unhide Audio Tracks
        </MenuItem>
        {user && <MenuItem onClick={handleSignOut}>Logout</MenuItem>}
      </MenuContainer>
      <AboutDialog
        open={aboutOpen}
        onClose={() => {
          setAboutOpen(false);
          setMenuOpen(false);
        }}
      />
    </div>
  );
}
