import { useState, useEffect, useCallback } from 'react';
import { Participant, RemoteTrackPublication, RemoteDataTrack } from 'twilio-video';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import { TimerDisplType, RemoteTimerDisplDefaults } from '../types/FitnessTimerTypes';
import { Howl } from 'howler';
// @ts-ignore
import { useSSE } from 'react-hooks-sse';
import useRoomState from '../../../hooks/useRoomState/useRoomState';

Howler.volume(0.2);
const sound = new Howl({ src: ['/beeps.webm', '/beeps.mp3'], volume: 0.5, preload: true });
const tick = new Howl({ src: ['/tick.webm', '/tick.mp3'], preload: true });

export default function useTimerRemoteState() {
  const { room } = useVideoContext();
  const roomState = useRoomState();
  const sseState = useSSE('timer');
  const [remoteTimerState, setRemoteTimerState] = useState<TimerDisplType>(RemoteTimerDisplDefaults);
  const [timerBroadcasting, setTimerBroadcasting] = useState<boolean>(false);

  const timerMsgReceived = (msg: string) => {
    const data = JSON.parse(msg);
    setRemoteTimerState(data as TimerDisplType);
  };

  useEffect(() => {
    if (roomState != 'connected') return;
    if (!sseState) return;
    setTimerBroadcasting(true);
    setRemoteTimerState(sseState.data as TimerDisplType);
    if (sseState.data.state == 'Done') {
      setTimeout(() => {
        setTimerBroadcasting(false);
      }, 1000);
    }
  }, [sseState]);

  // Play Sounds
  useEffect(() => {
    if (roomState != 'connected') return;
    if (remoteTimerState.countdown < 2900) return;
    if (remoteTimerState.countdown < 3100) {
      if (!sound.playing()) {
        sound.play();
        return;
      }
    }
    if (remoteTimerState.countdown % 1000 === 0) {
      if (!sound.playing()) {
        tick.play();
      }
    }
  }, [remoteTimerState.countdown]);

  const trackSubscribed = (participant: Participant, track: RemoteDataTrack) => {
    setTimerBroadcasting(true);
    console.log(
      `LocalParticipant subscribed to RemoteParticipant "${participant.identity}"'s ${track.kind} Track ${track.sid}`
    );
    track.on('message', timerMsgReceived);
  };

  const trackUnsubscribed = (participant: Participant, track: RemoteDataTrack) => {
    setTimerBroadcasting(false);
    if (sound.playing()) {
      sound.stop();
    }
    console.log(
      `LocalParticipant unsubscribed from RemoteParticipant "${participant.identity}"'s ${track.kind} Track ${track.sid}`
    );
  };

  const trackPublished = (participant: Participant, publication: RemoteTrackPublication) => {
    if (publication.kind !== 'data') return;
    if (participant === room.localParticipant) return;
    if (sound.playing()) {
      sound.stop();
    }
    console.log(
      `RemoteParticipant "${participant.identity}" published ${publication.kind} Track ${publication.trackSid}`
    );
    if (publication.isSubscribed) {
      trackSubscribed(participant, publication.track as RemoteDataTrack);
    } else {
      publication.on('subscribed', track => trackSubscribed(participant, track));
    }
    publication.on('unsubscribed', track => trackUnsubscribed(participant, track));
  };

  const trackUnpublished = (participant: Participant, publication: RemoteTrackPublication) => {
    console.log(
      `RemoteParticipant "${participant.identity}" unpublished ${publication.kind} Track ${publication.trackSid}`
    );
  };

  const participantConnected = (participant: Participant) => {
    if (participant === room.localParticipant) return;
    console.log(`RemoteParticipant "${participant.identity}" connected`);
    participant.tracks?.forEach(publication => trackPublished(participant, publication as RemoteTrackPublication));
    participant.on('trackPublished', publication => trackPublished(participant, publication));
    participant.on('trackUnpublished', publication => trackUnpublished(participant, publication));
  };

  const participantDisconnected = (participant: Participant) => {
    console.log(`RemoteParticipant "${participant.identity}" disconnected`);
  };

  useEffect(() => {
    room.participants && room.participants.forEach(participantConnected);
    room.on('participantConnected', participantConnected);
    room.on('participantDisconnected', participantDisconnected);
  }, [room]);

  return { remoteTimerState, timerBroadcasting };
}
