import { useState, useCallback, useEffect } from 'react';
import Video, { LocalAudioTrack, CreateLocalTrackOptions } from 'twilio-video';
import { ensureMediaPermissions } from '../../utils';

export function useLocalMusicTrack() {
  const [track, setTrack] = useState<LocalAudioTrack>();

  const getLocalMusicTrack = useCallback((deviceId?: string | null | undefined) => {
    if (!deviceId) return;
    const options: CreateLocalTrackOptions = {
      echoCancellation: false,
      noiseSuppression: false,
      autoGainControl: false,
      sampleRate: 48000,
      sampleSize: 16,
      deviceId: { exact: deviceId },
    };

    return ensureMediaPermissions().then(() =>
      Video.createLocalAudioTrack(options).then(newTrack => {
        setTrack(newTrack);
        return newTrack;
      })
    );
  }, []);

  useEffect(() => {
    const handleStopped = () => setTrack(undefined);
    if (track) {
      track.on('stopped', handleStopped);
      return () => {
        track.off('stopped', handleStopped);
      };
    }
  }, [track]);

  return [track, getLocalMusicTrack] as const;
}
