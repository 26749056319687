import React, { useState, useEffect } from 'react';
import { Paper, makeStyles, List, ListItem, Divider, ListSubheader, createStyles, Theme } from '@material-ui/core';
import { CosmicTile } from '../ScheduleChooser/CosmicTile';
import moment from 'moment';
//@ts-ignore
import AddToCalendar from 'react-add-to-calendar';
import theme from '../../theme';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: '1em',
      borderRadius: '0.5em',
      background: '#303030',
      height: 'min-content',
      display: 'flex',
      letterSpacing: '0.05em',
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
    },
    tile: {
      width: '20em',
      height: '20em',
      margin: '0 1em 0 0',
      [theme.breakpoints.down('sm')]: {
        width: 'auto',
        height: 'auto',
        margin: 0,
      },
    },
    nfo: {
      display: 'flex',
      flexDirection: 'column',
    },
    title: {
      fontWeight: 'bold',
      backgroundColor: 'rgb(30,30,30)',
      padding: '1em',
      marginBottom: '0.5em',
      [theme.breakpoints.down('sm')]: {
        marginTop: '2em',
      },
      '& span': {
        fontSize: '0.875rem',
      },
    },
    description: {
      padding: '1em',
      backgroundColor: 'rgba(0,0,0,0.3)',
      width: '40em',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
  })
);

const fetchClassDetails = (id: number) => {
  return fetch(`https://cosmicfitclub.com/models/classdefs/${id}`, { credentials: 'include' }).then(x => x.json());
};

const fetchClassOccurrences = (id: number) => {
  return fetch(`https://cosmicfitclub.com/models/classdefs/${id}/next_occurrences/2`, {
    credentials: 'include',
  }).then(x => x.json());
};

const occToCalEvent = (occ: ClassDefScheduleOccurrence, cls: ClassDefType) => {
  console.log(occ.starttime);
  return {
    description: `${cls.name} w/ ${occ.instructors.map(x => x.name).join(', ')}`,
    endtime: occ.endtime,
    location: 'https://video.cosmicfitclub.com',
    starttime: occ.starttime,
    title: `${cls.name} w/ ${occ.instructors.map(x => x.name).join(', ')}`,
  };
};

export interface ClassDefScheduleOccurrence {
  sched_id: number;
  starttime: string;
  capacity: number;
  endtime: string;
  headcount: number;
  instructors: [{ id: number; name: string }];
  // occurrence: {id: 5684, starttime: "2020-06-05T16:30:00.000-04:00", classdef_id: 161, staff_id: 104, capacity: 20,…}
  // scheduled: {starttime: "2020-06-05T16:30:00-04:00", teachers: [{id: 104, name: "Sylvana Tapia"}]}
  // exception: null;
}

export interface ClassDefType {
  id: number;
  name: string;
  capacity: number;
  category: string;
  deactivated: boolean;
  description: string;
  image_url: string;
  kids: boolean;
  position: number;
  unpaid: boolean;
}

export default function ClassDetailView({ id }: { id: number }) {
  const [classDetails, setClassDetails] = useState<ClassDefType | null>(null);
  const [classOccurrences, setClassOccurrences] = useState<ClassDefScheduleOccurrence[]>([]);
  const classes = useStyles();

  useEffect(() => {
    fetchClassDetails(id).then(x => setClassDetails(x));
    fetchClassOccurrences(id).then(x => setClassOccurrences(x));
  }, [id]);

  if (!classDetails) return null;

  let icon = { 'calendar-plus-o': 'left' };

  return (
    <Paper className={classes.paper}>
      <CosmicTile src={classDetails?.image_url || ''} className={classes.tile} />
      <div className={classes.nfo}>
        <div className={classes.title}>
          <span>{classDetails?.name}</span>
        </div>
        <div className={classes.description}>{classDetails?.description}</div>
        <List>
          <ListSubheader style={{ backgroundColor: 'rgb(30,30,30)' }}>Upcoming Meeting Times:</ListSubheader>
          <Divider />
          {classOccurrences.map(occ => (
            <ListItem divider={true}>
              <span>
                {moment(occ.starttime).format('h:mm a ddd MM/DD/YYYY')} w/ {occ.instructors.map(x => x.name).join(', ')}
              </span>
              <AddToCalendar event={occToCalEvent(occ, classDetails)} />
            </ListItem>
          ))}
        </List>
      </div>
    </Paper>
  );
}
