import { ScheduleOccurrence } from './useCosmicSchedule';
import React from 'react';
import { ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import moment from 'moment-timezone';
import { CosmicTile } from './CosmicTile';

export type ScheduleListItemProps = {
  occ: ScheduleOccurrence | undefined;
  time?: number | null | undefined;
  handler?: (occ: ScheduleOccurrence) => void | undefined;
};

const msToString = (ms: number) => {
  if (!ms) return '';
  ms = Math.abs(ms);
  let dateObj = new Date(ms);
  let hours = dateObj.getUTCHours();
  let minutes = dateObj.getUTCMinutes();
  let seconds = dateObj.getSeconds();
  return (
    hours.toString().padStart(2, '0') +
    ':' +
    minutes.toString().padStart(2, '0') +
    ':' +
    seconds.toString().padStart(2, '0')
  );
};

export default function ScheduleListItem({ occ, time, handler }: ScheduleListItemProps) {
  if (!occ) return null;
  let timeStr = moment(occ.starttime).format('h:mm a');

  return (
    <ListItem
      divider={true}
      className="listItem"
      key={occ.roomStr}
      onClick={() => {
        if (handler) handler(occ);
      }}
    >
      {time && time > 0 && (
        <div style={{ position: 'absolute', top: '0.25rem', right: '0.5rem', fontSize: '0.75rem' }}>
          Starts In: {msToString(time)}
        </div>
      )}
      {time && time <= 0 && (
        <div style={{ position: 'absolute', top: '0.25rem', right: '0.5rem', fontSize: '0.75rem', color: '#88DD88' }}>
          Elapsed Time: {msToString(time)}
        </div>
      )}
      <ListItemIcon>
        <CosmicTile src={occ.thumb_url} style={{ fontSize: '0.3rem', marginRight: '1rem' }} />
      </ListItemIcon>
      <ListItemText
        style={{ margin: '1rem 0' }}
        primary={timeStr + ' - ' + occ.listLines[0]}
        secondary={occ.listLines[1] + occ.listLines[2]}
      />
    </ListItem>
  );
}
