import React, { ChangeEvent, FormEvent, useState, useEffect } from 'react';
import { useAppState } from '../../../state';
import useVideoContext from '../../../hooks/useVideoContext/useVideoContext';
import { TextField, makeStyles, createStyles, Theme, Typography, CircularProgress, Button } from '@material-ui/core';
import ScheduleChooser from '../../../custom/ScheduleChooser/ScheduleChooser';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    form: {
      position: 'relative',
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      [theme.breakpoints.up('md')]: {
        marginLeft: '2.2em',
      },
      [theme.breakpoints.down('md')]: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        marginTop: '2em',
      },
    },
    nameField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      maxWidth: 200,
      [theme.breakpoints.down('md')]: {
        marginBottom: theme.spacing(1),
      },
    },
    nameDisplay: {
      margin: '1.1em 0.6em',
      minWidth: '200px',
      fontWeight: 600,
    },
    submit: {
      [theme.breakpoints.down('md')]: {
        width: '100%',
        [theme.breakpoints.down('md')]: {
          marginTop: theme.spacing(1),
        },
      },
    },
    loadingSpinner: {
      marginLeft: '1em',
    },
  })
);

export default function LoginForm() {
  const classes = useStyles();
  const { user, getToken, isFetching } = useAppState();
  const { isConnecting, connect } = useVideoContext();

  const [name, setName] = useState<string>(user?.displayName || '');
  const [roomName, setRoomName] = useState<string>('');

  useEffect(() => {
    setName(user?.displayName || '');
  }, [user]);

  const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    window.history.replaceState(null, '', window.encodeURI(`/room/${roomName}`));
    getToken(name, roomName).then(token => connect(token));
  };

  return (
    <form className={classes.form} onSubmit={handleSubmit}>
      {!user?.displayName ? (
        <TextField
          id="menu-name"
          label="Enter Your Name"
          InputProps={{ style: { fontSize: 'min(1em,3vw)' } }}
          InputLabelProps={{ style: { fontSize: 'min(0.8em,2.6vw)' } }}
          className={classes.nameField}
          value={name}
          onChange={handleNameChange}
          margin="dense"
        />
      ) : (
        <Typography className={classes.nameDisplay} variant="body1">
          {user.displayName}
        </Typography>
      )}
      <ScheduleChooser onChoice={setRoomName} />
      <Button
        className={classes.submit}
        type="submit"
        color="primary"
        variant="contained"
        disabled={isConnecting || !name || !roomName}
      >
        Join Room
      </Button>
      {(isConnecting || isFetching) && <CircularProgress className={classes.loadingSpinner} />}
    </form>
  );
}
