import React, { ChangeEvent, FormEvent, useEffect, useState } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

import AppBar from '@material-ui/core/AppBar';
import ToggleFullscreenButton from './ToggleFullScreenButton/ToggleFullScreenButton';
import Toolbar from '@material-ui/core/Toolbar';
import Menu from './Menu/Menu';
import PayPalButton from './PayPalButton/PayPalButton';

import { useAppState } from '../../state';
import useRoomState from '../../hooks/useRoomState/useRoomState';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import FlipCameraButton from './FlipCameraButton/FlipCameraButton';
import { DeviceSelector } from './DeviceSelector/DeviceSelector';
import LoginForm from './LoginForm/LoginForm';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: theme.palette.background.default,
    },
    toolbar: {
      [theme.breakpoints.down('md')]: {
        padding: '2em 0.5em 0 0.5em',
      },
    },
    rightButtonContainer: {
      position: 'absolute',
      right: 0,
      left: 'auto',
      top: 0,
      bottom: 0,
      display: 'flex',
      alignItems: 'center',
      marginLeft: 'auto',
      [theme.breakpoints.down('md')]: {
        bottom: 'auto',
      },
    },
    displayName: {
      margin: '1.1em 0.6em',
      minWidth: '200px',
      fontWeight: 600,
    },
    donateMargins: {
      margin: '0 1em',
    },
    inlineBlock: {
      display: 'inline-block',
      fontSize: 'min(1em,3vw)',
    },
  })
);

const msToString = (ms: number) => {
  if (!ms) return '';
  ms = Math.abs(ms);
  let dateObj = new Date(ms);
  let hours = dateObj.getUTCHours();
  let minutes = dateObj.getUTCMinutes();
  let seconds = dateObj.getSeconds();
  return (
    hours.toString().padStart(2, '0') +
    ':' +
    minutes.toString().padStart(2, '0') +
    ':' +
    seconds.toString().padStart(2, '0')
  );
};

export default function MenuBar(props: { onLayout: (l: string) => void }) {
  const classes = useStyles();
  const { room, occurrence } = useVideoContext();
  const roomState = useRoomState();

  const [time, setTime] = useState<number>(0);

  //const [name, setName] = useState<string>(user?.displayName || '');
  //const [roomName, setRoomName] = useState<string>('');

  useEffect(() => {
    const interval = setInterval(() => {
      let start = Date.parse(occurrence?.starttime || '');
      setTime(Date.now() - start);
    }, 1000);
    return () => clearInterval(interval);
  }, [occurrence]);

  useEffect(() => {
    console.log(
      Date.now() +
        '  ' +
        Date.parse(occurrence?.starttime || '') +
        '  ' +
        time +
        '  ' +
        msToString(time) +
        '  ' +
        (time > 3600000 ? 'true' : 'false') +
        (time < 0 ? 'true' : 'false')
    );
  }, [time]);

  return (
    <AppBar className={classes.container} position="static">
      <Toolbar className={classes.toolbar}>
        {roomState === 'disconnected' ? (
          <LoginForm />
        ) : (
          <span>
            <h3 title={occurrence?.sched_id?.toString()} className={classes.inlineBlock}>
              {room.name}
            </h3>
            {time && time > 0 && time < 3600000 && (
              <span
                style={{
                  margin: '0 1em',
                  padding: '0.5em',
                  border: '1px solid white',
                  background: 'black',
                  color: 'lightgreen',
                  fontWeight: 'bold',
                }}
              >
                Elapsed: {msToString(time)}
              </span>
            )}
            {time && time > 3600000 && (
              <span
                style={{
                  margin: '0 1em',
                  padding: '0.5em',
                  border: '1px solid white',
                  background: 'black',
                  color: 'red',
                  fontWeight: 'bold',
                }}
              >
                Ended: {msToString(time - 3600000)} Ago
              </span>
            )}
            {time && time < 0 && (
              <span
                style={{
                  margin: '0 1em',
                  padding: '0.5em',
                  border: '1px solid white',
                  background: 'black',
                  color: 'blue',
                  fontWeight: 'bold',
                }}
              >
                Starts In: {msToString(time)}
              </span>
            )}
          </span>
        )}
        <div className={classes.rightButtonContainer}>
          <PayPalButton />
          <FlipCameraButton />
          <DeviceSelector />
          <ToggleFullscreenButton />
          <Menu onLayout={l => props.onLayout(l)} />
        </div>
      </Toolbar>
    </AppBar>
  );
}
