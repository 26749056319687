import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import { TimerDisplType, TimerDisplDefaults } from './types/FitnessTimerTypes';
import FitnessTimerDisplay from './components/FitnessTimerDisplay';
import useTimerRemoteState from './hooks/useTimerRemoteState';

const useStyles = makeStyles({
  visible: {
    display: 'inline-block',
    position: 'fixed',
    width: '20vw',
    height: 'min-content',
    fontSize: '3.5vw',
    bottom: 0,
    right: 0,
    zIndex: 999,
  },
  invisible: {
    display: 'none',
  },
});

export default function FitnessTimerPopup() {
  const { remoteTimerState, timerBroadcasting } = useTimerRemoteState();
  const [timerDispl, setTimerDispl] = useState<TimerDisplType>(TimerDisplDefaults);
  const classes = useStyles();

  useEffect(() => {
    if (!remoteTimerState) return;
    setTimerDispl(remoteTimerState);
  }, [remoteTimerState]);

  return (
    <div className={timerBroadcasting ? classes.visible : classes.invisible}>
      <FitnessTimerDisplay {...timerDispl} />
    </div>
  );
}
