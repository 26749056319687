import React, { useEffect, useState } from 'react';
import { FormControl, MenuItem, Typography, Select } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useAudioInputDevices } from '../../components/MenuBar/DeviceSelector/deviceHooks/deviceHooks';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import { useLocalMusicTrack } from './useLocalMusicTrack';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
  },
});

export default function MusicInputList() {
  const classes = useStyles();
  const audioInputDevices = useAudioInputDevices();
  const [track, getLocalMusicTrack] = useLocalMusicTrack();
  const [localMusicDeviceId, setLocalMusicDeviceId] = useState<string>('');
  const {
    room: { localParticipant },
  } = useVideoContext();

  function replaceTrack(newDeviceId: string) {
    if (track) {
      track.stop();
      const localTrackPublication = localParticipant?.unpublishTrack(track);
      localParticipant?.emit('trackUnpublished', localTrackPublication);
    }
    if (!newDeviceId) return;
    getLocalMusicTrack(newDeviceId)?.then(newTrack => {
      localParticipant?.publishTrack(newTrack);
    });
  }

  useEffect(() => {
    console.log(track?.mediaStreamTrack.getSettings());
    setLocalMusicDeviceId(track?.mediaStreamTrack.getSettings().deviceId || '');
  }, [track]);

  return (
    <div className={classes.container}>
      <div className="inputSelect">
        {audioInputDevices.length > 1 ? (
          <FormControl fullWidth>
            <Typography variant="h6">Music Input:</Typography>
            <Select onChange={e => replaceTrack(e.target.value as string)} value={localMusicDeviceId || ''}>
              <MenuItem value="">No Music</MenuItem>
              {audioInputDevices.map(device => (
                <MenuItem value={device.deviceId} key={device.deviceId}>
                  {device.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        ) : (
          <>
            <Typography variant="h6">Music Input:</Typography>
            <Typography>{track?.mediaStreamTrack.label || 'No Local Music'}</Typography>
          </>
        )}
      </div>
    </div>
  );
}
