import { useCallback, useState, useEffect } from 'react';

export interface CosmicUserType {
  user_id: number;
  customer_id: number;
  customer_name: string;
  customer_email: string;
  displayName: string | null;
  photoURL: string | null;
}

export function throwHTTPStatusErrs(resp: Response): Promise<any> {
  if (resp.ok) return resp.json();
  return resp.text().then((text: string) => {
    throw new Error(text);
  });
  //.catch( e => console.log(e) )
}

export default function useCosmicAuth() {
  const [user, setUser] = useState<CosmicUserType | null>(null);
  const [isAuthReady, setIsAuthReady] = useState(false);

  useEffect(() => {
    fetch(`https://cosmicfitclub.com/auth/test_jwt`, { credentials: 'include' })
      .then(res => {
        if (!res.ok) return;
        return res.json();
      })
      .then(data => {
        if (!data) return;
        let user = data[0]['user'];
        user.displayName = user.customer_name;
        setUser(user);
        setIsAuthReady(true);
      });
  }, []);

  const getToken = useCallback(async (identity: string, roomName: string) => {
    const headers = new window.Headers();
    const endpoint = process.env.REACT_APP_TOKEN_ENDPOINT || '/token';
    const params = new window.URLSearchParams({ identity, roomName });
    return fetch(`${endpoint}?${params}`, { headers }).then(res => res.text());
  }, []);

  const checkEmail = useCallback((email: string) => {
    return fetch(`https://cosmicfitclub.com/auth/email_search?email=${email}`).then(res => res.json());
  }, []);

  const signIn = useCallback((email: string, password: string) => {
    let data = new FormData();
    data.append('email', email);
    data.append('password', password);
    return fetch(`https://cosmicfitclub.com/auth/login_jwt`, {
      method: 'POST',
      body: data,
      credentials: 'include',
    })
      .then(throwHTTPStatusErrs)
      .then(data => {
        let user = data[0]['user'];
        user.displayName = user.customer_name;
        setUser(user);
        setIsAuthReady(true);
      });
  }, []);

  const createAccount = useCallback((email: string, fullname: string, password: string) => {
    let data = new FormData();
    data.append('email', email);
    data.append('password', password);
    data.append('name', fullname);
    return fetch(`https://cosmicfitclub.com/auth/register_and_login_jwt`, {
      method: 'POST',
      body: data,
      credentials: 'include',
    })
      .then(throwHTTPStatusErrs)
      .then(data => {
        let user = data[0]['user'];
        user.displayName = user.customer_name;
        setUser(user);
        setIsAuthReady(true);
      });
  }, []);

  const sendResetLink = useCallback((email: string) => {
    let data = new FormData();
    data.append('email', email);
    return fetch('https://cosmicfitclub.com/auth/reset', {
      method: 'POST',
      body: data,
      credentials: 'include',
    }).then(throwHTTPStatusErrs);
  }, []);

  const resetPassword = useCallback((token: string, password: string) => {
    let data = new FormData();
    data.append('token', token);
    data.append('password', password);
    data.append('confirmation', password);
    return fetch('https://cosmicfitclub.com/auth/password', {
      method: 'POST',
      body: data,
      credentials: 'include',
    })
      .then(throwHTTPStatusErrs)
      .then(data => {
        let user = data[0]['user'];
        user.displayName = user.customer_name;
        setUser(user);
        setIsAuthReady(true);
      });
  }, []);

  const signOut = useCallback(() => {
    setUser(null);
    setIsAuthReady(false);
    fetch('https://cosmicfitclub.com/auth/logout_jwt', { method: 'POST', credentials: 'include' });
    return Promise.resolve();
  }, []);

  return { user, isAuthReady, getToken, signIn, signOut, checkEmail, sendResetLink, resetPassword, createAccount };
}
