import React from 'react';
//import { useAppState } from '../../state';

import { Grid, Paper } from '@material-ui/core';

import RoomList from './RoomList/RoomList';

import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
//import { useLocation, useHistory } from 'react-router-dom';

const useStyles = makeStyles({
  container: {
    height: '100vh',
    background: '#0D122B',
  },
  paper: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: '2em',
    marginTop: '4em',
    background: 'white',
    color: 'black',
  },
});

const theme = createMuiTheme({
  palette: {
    type: 'light',
  },
});

export default function AdminPage() {
  const classes = useStyles();
  //const { signIn, user, isAuthReady } = useAppState();
  //const history = useHistory();
  //const location = useLocation<{ from: Location }>();
  //const [passcode, setPasscode] = useState('');
  //const [authError, setAuthError] = useState<Error | null>(null);

  //const isAuthEnabled = Boolean(process.env.REACT_APP_SET_AUTH);

  return (
    <ThemeProvider theme={theme}>
      <Grid container justify="center" alignItems="flex-start" className={classes.container}>
        <Paper className={classes.paper} elevation={6}>
          <RoomList />
        </Paper>
      </Grid>
    </ThemeProvider>
  );
}
