import React from 'react';
import { makeStyles } from '@material-ui/core';
import moment from 'moment';
import { TimerDisplType } from '../types/FitnessTimerTypes';

const useStyles = makeStyles({
  clock: {
    backgroundColor: (props: { color: string }) => props.color,
    textAlign: 'center',
    padding: '0.4em',
    fontSize: '1em',
    lineHeight: '1.3em',
    fontWeight: 'bold',
    boxShadow: '0 0 1em rgba(0,0,0,0.5) inset',
  },
  half_container: {
    display: 'flex',
  },
  halfwidth: {
    flex: '1',
    lineHeight: '1.3em',
    fontSize: '0.5em',
  },
});

const timeString = (clock: number) => {
  let time = moment.duration(clock + 999);
  return `${time.minutes()}:${time.seconds()}`;
};

export default function FitnessTimerDisplay(props: TimerDisplType) {
  const classes = useStyles({ color: props.color });

  return (
    <div className={classes.clock}>
      <div>{props.state}</div>
      <div>{timeString(props.countdown)}</div>
      <div className={classes.half_container}>
        <span className={classes.halfwidth}>Cycles{props.cycles | 0}</span>
        <span className={classes.halfwidth}>Tabatas{props.tabatas | 0}</span>
      </div>
    </div>
  );
}
