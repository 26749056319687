import React, { useState, useEffect } from 'react';
import {
  Modal,
  makeStyles,
  createStyles,
  Theme,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Divider,
} from '@material-ui/core';
import { loadStripe, PaymentMethod, PaymentIntent } from '@stripe/stripe-js';
import { Elements, useStripe } from '@stripe/react-stripe-js';
import NewStripeCheckout from './StripeCheckout/NewStripeCheckout';
import SavedUserCards, { PaymentSource } from './SavedUserCards/SavedUserCards';

const STRIPE_PUBLIC = process.env.REACT_APP_STRIPE_PUBLIC as string;
const stripePromise = loadStripe(STRIPE_PUBLIC);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      cursor: 'pointer',
      position: 'absolute',
      top: 0,
      bottom: 0,
      margin: 'auto',
      height: '1.8em',
      right: '1em',
      borderRadius: '0.3em',
      padding: '0 1em',
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    container: {
      background: 'rgb(50,50,50)',
      borderRadius: '2em',
      boxShadow: '0 0 2em black inset',
      padding: '1em',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
  })
);

interface ClassPackage {
  id: number;
  name: string;
  num_passes: number;
  pass_price: number;
  presale: boolean;
  stripe_id: string;
  available: boolean;
  available_at_desk: boolean;
}

const getPackDefinitions = () => {
  return fetch(`https://cosmicfitclub.com/models/passes/packages`).then(resp => resp.json());
};

const getPaymentIntent = (amount: number, description: string) => {
  return fetch(`https://cosmicfitclub.com/checkout/create_intent`, {
    method: 'POST',
    mode: 'cors',
    credentials: 'include',
    body: JSON.stringify({ amount: amount, description: description }),
    headers: new Headers({ 'Content-type': 'application/json' }),
  }).then(resp => resp.json());
};

const updatePaymentIntent = (amount: number, description: string, intent: PaymentIntent) => {
  return fetch(`https://cosmicfitclub.com/checkout/update_intent`, {
    method: 'POST',
    mode: 'cors',
    credentials: 'include',
    body: JSON.stringify({ amount: amount, description: description, intent_id: intent.id }),
    headers: new Headers({ 'Content-type': 'application/json' }),
  }).then(resp => resp.json());
};

const buyPackage = (intent_id: string, pack_id: number) => {
  return fetch(`https://cosmicfitclub.com/checkout/pack/intent`, {
    method: 'POST',
    mode: 'cors',
    credentials: 'include',
    body: JSON.stringify({ intent_id: intent_id, pack_id: pack_id }),
    headers: new Headers({ 'Content-type': 'application/json' }),
  }).then(resp => resp.json());
};

export default function BuyClassPack(attr: { onComplete: () => void }) {
  return (
    <Elements stripe={stripePromise}>
      <BuyClassPackInternal onComplete={attr.onComplete} />
    </Elements>
  );
}

function BuyClassPackInternal(attr: { onComplete: () => void }) {
  const [open, setOpen] = useState<boolean>(false);
  const [packages, setPackages] = useState<ClassPackage[]>([]);
  const [pack, setPack] = useState<number>(0);
  const [intent, setIntent] = useState<PaymentIntent>();
  const [busy, setBusy] = useState<boolean>(false);
  const [refreshSaved, setRefreshSaved] = useState<boolean>(false);
  const stripe = useStripe();
  const classes = useStyles();

  const checkout = (src: PaymentMethod | PaymentSource, save: boolean | undefined) => {
    console.log(src);
    if (!intent) return;
    if (!intent.client_secret) return;
    let opts = { payment_method: src.id };
    if (save) {
      Object.assign(opts, { setup_future_usage: 'off_session' });
    }
    setBusy(true);
    stripe?.confirmCardPayment(intent.client_secret, opts).then(val => {
      if (!val.paymentIntent) {
        setBusy(false);
        return;
      }
      setIntent(val.paymentIntent);
      buyPackage(val.paymentIntent.id, pack).then(val => {
        setOpen(false);
        setBusy(false);
        attr.onComplete();
      });
    });
  };

  useEffect(() => {
    getPackDefinitions().then(val => setPackages(val as ClassPackage[]));
  }, []);

  useEffect(() => {
    if (!packages.length) return;
    setPack(packages[0].id);
  }, [packages]);

  const handleClick = () => {
    getPaymentIntent(packages[0].num_passes * packages[0].pass_price, packages[0].name).then(val => setIntent(val));
    setOpen(true);
  };

  const choosePack = (pack_id: number) => {
    setPack(pack_id);
    let packobj = packages.find(x => x.id == pack_id);
    if (!packobj) return;
    if (intent) {
      updatePaymentIntent(packobj.num_passes * packobj.pass_price, packobj.name, intent).then(val => setIntent(val));
    } else {
      getPaymentIntent(packobj.num_passes * packobj.pass_price, packobj.name).then(val => setIntent(val));
    }
  };

  return (
    <div onClick={ev => ev.stopPropagation()}>
      <button className={classes.button} onClickCapture={handleClick}>
        Buy More
      </button>
      <Modal open={open} onClose={() => setOpen(false)} className={classes.modal}>
        <>
          {busy && <CircularProgress />}
          {!busy && (
            <div className={classes.container}>
              <h3>Purchase a Class Package</h3>
              <InputLabel id="packageSelect">Choose a Package</InputLabel>
              <Select value={pack} labelId="packageSelect" onChange={e => choosePack(e.target.value as number)}>
                {packages.map(p => (
                  <MenuItem value={p.id}>
                    {p.name} - ${((p.num_passes * p.pass_price) / 100).toFixed(2)}
                  </MenuItem>
                ))}
              </Select>
              <Divider />
              <SavedUserCards
                onCard={checkout}
                refresh={refreshSaved}
                onBusy={value => {
                  setBusy(value);
                }}
              />
              <Divider />
              <NewStripeCheckout
                onCard={checkout}
                onCardSaved={() => {
                  setRefreshSaved(!refreshSaved);
                }}
              />
            </div>
          )}
        </>
      </Modal>
    </div>
  );
}
