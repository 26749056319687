import useParticipants from '../../../hooks/useParticipants/useParticipants';
import { useEffect, useState } from 'react';
import { RemoteParticipant } from 'twilio-video';

export default function useRemoteMusicParticipant() {
  const { participants } = useParticipants();
  const [musicParticipant, setMusicParticipant] = useState<RemoteParticipant | undefined>();

  useEffect(() => {
    let p = participants.find(x => x.identity === 'music');
    setMusicParticipant(p);
  }, [participants]);

  return musicParticipant;
}
