export interface TimerPropsType {
  prepare: number;
  work: number;
  rest: number;
  cycles: number;
  tabatas: number;
}

export interface TimerStateType {
  offset: number;
  clock: number;
  interval: number;
}

export interface TimerDisplType {
  tabatas: number;
  cycles: number;
  countdown: number;
  state: string;
  color: string;
}

export interface TimerActivity {
  name: string;
  color: string;
}

export interface TimerActivityBlock {
  activity: TimerActivity;
  duration: number;
}

export interface TimerFlow {
  name: string;
  blocks: TimerActivityBlock[];
}

export interface TimerListType {
  name: string;
  list: TimerActivityBlock[];
}

export const TimerPropsDefaults: TimerPropsType = {
  prepare: 10,
  work: 20,
  rest: 10,
  cycles: 8,
  tabatas: 1,
};

export const TimerStateDefaults: TimerStateType = {
  offset: 0,
  clock: 0,
  interval: 0,
};

export const TimerDisplDefaults: TimerDisplType = {
  tabatas: 2,
  cycles: 8,
  countdown: 0,
  state: 'prepare',
  color: '#AAAA00',
};

export const RemoteTimerDisplDefaults: TimerDisplType = {
  tabatas: 0,
  cycles: 0,
  countdown: 0,
  state: 'Done',
  color: '#FF0000',
};
