import React, { useState, useEffect, useCallback } from 'react';
import { List, ListItem, ListItemText, Button } from '@material-ui/core';

export default function ParticipantList(props: { roomsid: any }) {
  const [participants, setParticipants] = useState<any[]>([]);

  console.log(participants);

  const fetchParticipants = useCallback(async () => {
    let resp = await fetch(`/twilio/participant_list?room=${props.roomsid}`);
    let r = await resp.json();
    setParticipants(r);
  }, [setParticipants, props.roomsid]);

  const kickParticipant = async (name: string) => {
    await fetch(`/twilio/kick_participant?room=${props.roomsid}&name=${name}`);
    fetchParticipants();
  };

  useEffect(() => {
    fetchParticipants();
  }, [fetchParticipants]);

  return (
    <List>
      {participants.map(participant => (
        <ListItem>
          <ListItemText primary={participant.identity} />
          <Button onClick={() => kickParticipant(participant.identity)}>Kick</Button>
        </ListItem>
      ))}
    </List>
  );
}
