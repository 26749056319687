import React, { useState, useEffect } from 'react';
import DropIn from 'braintree-web-drop-in-react';

export default function BraintreeCheckout() {
  const [clientToken, setClientToken] = useState<string>('');
  const [instance, setInstance] = useState();

  useEffect(() => {
    fetch('https://cosmicfitclub.com/braintree/client_token', { credentials: 'include' })
      .then(x => x.text())
      .then(x => setClientToken(x));
  }, []);

  if (!clientToken) {
    return null;
  }

  return (
    <div>
      <DropIn options={{ authorization: clientToken, venmo: {} }} onInstance={i => setInstance(i)} />
    </div>
  );
}
