import React, { useState } from 'react';

import { AppBar, makeStyles, createStyles, Theme, Button, Typography } from '@material-ui/core';
import NoSleep from 'nosleep.js';

import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import useRoomState from '../../hooks/useRoomState/useRoomState';
import { useAppState } from '../../state';

import ScheduleChooser from '../ScheduleChooser/ScheduleChooser';
import FitnessTimer from './FitnessTimer';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appbar: {
      backgroundColor: theme.palette.background.default,
      width: '100%',
    },
    schedule_container: {
      padding: '2em',
      textAlign: 'center',
    },
    button: {
      fontSize: '2em',
    },
    displayName: {
      margin: '1.1em 0.6em',
      minWidth: '200px',
      fontWeight: 600,
    },
  })
);

export default function TimerPage() {
  const [roomName, setRoomName] = useState('');
  const { room, isConnecting, connect } = useVideoContext();
  const { getToken } = useAppState();
  const roomState = useRoomState();
  const classes = useStyles();
  const noSleep = new NoSleep();

  const startConnection = () => {
    noSleep.enable();
    getToken('timer', roomName)
      .then(token => connect(token))
      .then(() => console.log(JSON.stringify(room)));
  };

  const endConnection = () => {
    noSleep.disable();
    room.disconnect();
  };

  return (
    <>
      <AppBar className={classes.appbar} position="static">
        {roomState === 'disconnected' ? (
          <>
            <div className={classes.schedule_container}>
              <ScheduleChooser onChoice={setRoomName} />
            </div>
            <Button
              className={classes.button}
              onClick={startConnection}
              color="primary"
              variant="contained"
              disabled={isConnecting || !roomName}
            >
              Join Room
            </Button>
          </>
        ) : (
          <>
            <Typography className={classes.displayName} variant="body1">
              {room.name}
            </Typography>
            <Button className={classes.button} onClick={endConnection} color="primary" variant="contained">
              Leave Room
            </Button>
          </>
        )}
      </AppBar>
      <FitnessTimer />
    </>
  );
}
