import React, { useState, useEffect } from 'react';
import { ThemeProvider, Paper, makeStyles, Link } from '@material-ui/core';
import theme from '../CosmicTheme';
import { useAppState } from '../../state';
import ScheduleChooser2 from '../ScheduleChooser/ScheduleChooser2';
import CosmicLoginForm from '../CosmicLogin/CosmicLoginForm';
import UserClassCheckin from '../UserClassCheckin/UserClassCheckin';
import MenuBar from '../MenuBar/MenuBar';
import { ScheduleOccurrence } from '../ScheduleChooser/useCosmicSchedule';
import App from '../../App';
import useRoomState from '../../hooks/useRoomState/useRoomState';
require('dotenv').config();

const useStyles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    [theme.breakpoints.down('sm')]: {
      alignItems: 'flex-start',
      paddingTop: '2em',
    },
  },
  paper: {
    padding: '1.5em',
    borderRadius: '0.5em',
    background: 'rgba(0,0,0,0.7)',
    height: 'min-content',
    [theme.breakpoints.down('sm')]: {
      margin: '2vw',
      padding: '2vw',
    },
  },
  oldsite: {
    background: 'rgba(255,0,0,0.8)',
    display: 'block',
    color: 'white',
    padding: '0.25em',
    textAlign: 'center',
    fontSize: '1.3em',
    fontWeight: 'bold',
  },
});

export default function LandingPage() {
  const { user } = useAppState();
  const roomState = useRoomState();
  const [room, setRoom] = useState<ScheduleOccurrence | null>(null);
  const classes = useStyles();

  useEffect(() => {
    console.log(room);
  }, [room]);

  const clearRoom = () => {
    setRoom(null);
  };

  return (
    <ThemeProvider theme={theme}>
      {roomState === 'disconnected' ? (
        <>
          <MenuBar
            onLayout={(l: string) => {
              return;
            }}
          />
          <div className={classes.container}>
            <Paper className={classes.paper}>
              {!room && <ScheduleChooser2 onChoice={setRoom} />}
              {room && !user && <CosmicLoginForm />}
              {room && user && <UserClassCheckin occ={room} clearRoom={clearRoom} />}
            </Paper>
          </div>
        </>
      ) : (
        <App />
      )}
    </ThemeProvider>
  );
}
