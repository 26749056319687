import { useState, useEffect } from 'react';
import { isIOS, isMobileSafari, getUA } from 'react-device-detect';
import adr_fb_browser_img from './guide_imgs/adr_fb_browser.png';
import ios_fb_browser_img from './guide_imgs/ios_fb_browser.png';

export default function useBrowserSupport() {
  const hasGetUserMedia = () => !!(navigator.mediaDevices && navigator.mediaDevices.getUserMedia);

  if (getUA.indexOf('FBAN') > -1 || getUA.indexOf('FBAV') > -1)
    return {
      msg: `Facebook Embedded browser blocks camera access.. please use ${isIOS ? 'Safari' : 'Chrome or Firefox'}`,
      img: isIOS ? ios_fb_browser_img : adr_fb_browser_img,
    };

  if (getUA.indexOf('Instagram') > -1)
    return {
      msg: `Instagram Embedded browser blocks camera access.. please use ${isIOS ? 'Safari' : 'Chrome or Firefox'}`,
      img: isIOS ? ios_fb_browser_img : adr_fb_browser_img,
    };

  if (isIOS && !isMobileSafari)
    return {
      msg: 'Please use Safari on iOS devices. Apple blocks camera access to all other browsers.',
      img: '',
    };

  if (!hasGetUserMedia)
    return {
      msg: 'This browser does not have User Media support. Please try with another browser',
      img: '',
    };

  return { msg: null, img: null };
}
