import { useEffect, useState } from 'react';
import { LocalTrackPublication, Participant, RemoteTrackPublication } from 'twilio-video';
import { useAppState } from '../../state';

type TrackPublication = LocalTrackPublication | RemoteTrackPublication;

export default function usePublications(participant: Participant | undefined) {
  const [publications, setPublications] = useState<TrackPublication[]>([]);
  const { timerTrack, setTimerTrack } = useAppState();

  useEffect(() => {
    if (!participant) return;
    // Reset the publications when the 'participant' variable changes.
    setPublications(Array.from(participant.tracks.values()) as TrackPublication[]);

    const publicationAdded = (publication: TrackPublication) => {
      if (publication.kind === 'data') {
        console.log(`setting timer track ${JSON.stringify(publication)}`);
        console.log(`${JSON.stringify(participant.tracks.values())}`);
        setTimerTrack(publication);
        return;
      }
      setPublications(prevPublications => [...prevPublications, publication]);
    };
    const publicationRemoved = (publication: TrackPublication) => {
      if (publication === timerTrack) {
        console.log('clearing timer track');
        setTimerTrack(null);
        return;
      }
      setPublications(prevPublications => prevPublications.filter(p => p !== publication));
    };

    participant.on('trackPublished', publicationAdded);
    participant.on('trackUnpublished', publicationRemoved);
    return () => {
      participant.off('trackPublished', publicationAdded);
      participant.off('trackUnpublished', publicationRemoved);
    };
  }, [participant, setTimerTrack, timerTrack]);

  return publications;
}
