import React, { useState, useEffect } from 'react';
import { styled } from '@material-ui/core/styles';

import LocalVideoPreview from '../../components/LocalVideoPreview/LocalVideoPreview';
import MenuBar from '../../components/MenuBar/MenuBar';
import ReconnectingNotification from '../../components/ReconnectingNotification/ReconnectingNotification';
import Room from './Room/Room';

import useHeight from '../../hooks/useHeight/useHeight';
import useRoomState from '../../hooks/useRoomState/useRoomState';
import FitnessTimerPopup from '../../custom/FitnessTimer/FitnessTimerPopup';
import useCosmicSchedule from '../ScheduleChooser/useCosmicSchedule';
import { useAppState } from '../../state';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';

const Container = styled('div')({
  display: 'grid',
  gridTemplateRows: 'auto 1fr',
});

const Main = styled('main')({
  overflow: 'hidden',
  position: 'relative',
});

export default function FBView() {
  const roomState = useRoomState();
  const { schedule } = useCosmicSchedule();
  const [layout, setLayout] = useState('default');
  const { user, getToken } = useAppState();
  const { isConnecting, connect } = useVideoContext();

  // Here we would like the height of the main container to be the height of the viewport.
  // On some mobile browsers, 'height: 100vh' sets the height equal to that of the screen,
  // not the viewport. This looks bad when the mobile browsers location bar is open.
  // We will dynamically set the height with 'window.innerHeight', which means that this
  // will look good on mobile browsers even after the location bar opens or closes.
  const height = useHeight();

  //useEffect(() => {
  //  if (!schedule) return;
  //  const roomName =
  //    schedule.happening_now[0]?.roomStr || schedule.later_today[0]?.roomStr || schedule.flat_list[0].roomStr;
  //  getToken('fb', roomName).then(token => connect(token));
  //}, [schedule]);

  return (
    <Container style={{ height }}>
      <MenuBar onLayout={(l: string) => setLayout(l)} />
      <Main>{roomState === 'disconnected' ? <LocalVideoPreview /> : <Room layout={layout} />}</Main>
      <ReconnectingNotification />
      <FitnessTimerPopup />
    </Container>
  );
}
