import React, { useEffect, useState } from 'react';
import Participant from '../../components/Participant/Participant';
import { makeStyles, createStyles } from '@material-ui/core/styles';
import useParticipants from '../../hooks/useParticipants/useParticipants';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import { RemoteParticipant } from 'twilio-video';
//import useSelectedParticipant from '../VideoProvider/useSelectedParticipant/useSelectedParticipant';

const calcDimens = (count: number, width: number, height: number) => {
  let aspect = width / (height - 90);

  let props = (() => {
    switch (count) {
      case 1:
        return { asp: 16 / 9, cols: 1 };
      case 2:
        return { asp: 32 / 9, cols: 2 };
      case 3:
      case 4:
        return { asp: 16 / 9, cols: 2 };
      case 5:
      case 6:
        return { asp: 8 / 3, cols: 3 };
      case 7:
      case 8:
      case 9:
        return { asp: 16 / 9, cols: 3 };
      case 10:
      case 11:
      case 12:
        return { asp: 16 / 9, cols: 4 };
      case 13:
      case 14:
      case 15:
      case 16:
        return { asp: 20 / 9, cols: 4 };
      default:
        return { asp: 16 / 9, cols: 5 };
    }
  })();

  let w = aspect <= props.asp ? 100 / props.cols : ((props.asp / aspect) * 100) / props.cols;
  let h = (w / 16) * 9;
  return { width: w, height: h };
};

type StyleProps = { count: number; width: number; height: number };

const useStyles = makeStyles<unknown, StyleProps>(theme =>
  createStyles({
    grid: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      textAlign: 'center',
      '&::before': {
        content: '""',
        display: 'inline-block',
        height: '100%',
        width: 0,
        verticalAlign: 'middle',
      },
    },
    container: props => ({
      display: 'inline-block',
      verticalAlign: 'middle',
      '& #participantContainer': {
        display: 'inline-block',
        width: `${calcDimens(props.count, props.width, props.height).width}vw`,
        height: `${calcDimens(props.count, props.width, props.height).height}vw`,
        border: '3px solid black',
        background: 'black',
        maxHeight: 'none',
        marginBottom: '-6px',
      },
      '& video': {
        marginBottom: '-6px',
      },
    }),
  })
);

export default function GridLayout() {
  const [width, setWidth] = React.useState(window.innerWidth);
  const [height, setHeight] = React.useState(window.innerHeight);
  const { participants } = useParticipants();
  const [filteredParticipants, setFilteredParticipants] = useState<RemoteParticipant[]>([]);
  const {
    room: { localParticipant },
  } = useVideoContext();

  useEffect(() => {
    setFilteredParticipants(participants.filter(p => p.identity !== 'music'));
  }, [participants]);

  const count = participants.length + 1;
  const classes = useStyles({ count, width, height });

  const updateWidthAndHeight = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener('resize', updateWidthAndHeight);
    return () => window.removeEventListener('resize', updateWidthAndHeight);
  });

  return (
    <div className={classes.grid}>
      <div className={classes.container}>
        <Participant participant={localParticipant} isSelected={false} onClick={() => {}} />
        {filteredParticipants.map(participant => (
          <Participant key={participant.sid} participant={participant} isSelected={false} onClick={() => {}} />
        ))}
      </div>
    </div>
  );
}
