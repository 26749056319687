import moment from 'moment-timezone';
import { useEffect, useState, useCallback } from 'react';

export interface Schedule {
  earlier_today: ScheduleOccurrence[];
  happening_now: ScheduleOccurrence[];
  later_today: ScheduleOccurrence[];
  later_this_week: { [s: string]: ScheduleOccurrence[] };
  flat_list: ScheduleOccurrence[];
  by_day: { [s: string]: ScheduleOccurrence[] };
}

export interface ScheduleOccurrence {
  sched_id: number;
  type: string;
  day: string;
  starttime: string;
  endtime: string;
  title: string;
  event_title: string | undefined;
  capacity: number;
  headcount: number;
  classdef: { id: number; name: string };
  instructors: { id: number; name: string }[];
  scheduled: { starttime: string; teachers: { id: number; name: string }[] } | null;
  occurrence: any | null;
  exception: any | null;
  roomStr: string;
  localRoomStr: string;
  listLines: string[];
  thumb_url: string;
  localDate: string;
  allow_free: boolean;
}

const objectMap = (obj: any, fn: any) => {
  let val = Object.entries(obj);
  val = val.map(([k, v], i) => [k, fn(v, k, i)]);
  return Object.fromEntries(val);
};

const buildRoomString = (occ: ScheduleOccurrence) => {
  let nydate = moment(occ.starttime)
    .tz('America/New_York')
    .format('h:mma ddd MM/DD');
  let localdate = moment(occ.starttime).format('h:mma ddd MM/DD');
  if (occ.type === 'eventsession') {
    return {
      local: localdate,
      ny: nydate,
      roomstr: [occ.event_title, '', occ.title],
    };
  }
  let instructors = occ.instructors?.map((x: { name: any }) => x.name).join(', ');
  return { local: localdate, ny: nydate, roomstr: [occ.title, ' w/ ', instructors] };
};

const buildScheduleOccurrence = (occ: ScheduleOccurrence) => {
  if (!occ) return null;
  let rmstring = buildRoomString(occ);
  return {
    ...occ,
    localDate: rmstring.local,
    listLines: rmstring.roomstr,
    roomStr: `${rmstring.ny} - ${rmstring.roomstr.join('')}`,
    localRoomStr: `${rmstring.local} - ${rmstring.roomstr.join('')}`,
  } as ScheduleOccurrence;
};

const fetchData = (from: string, to: string) => {
  return fetch(`https://cosmicfitclub.com/models/schedule/sorted_schedule`)
    .then(resp => resp.json())
    .then(sched => {
      return {
        earlier_today: sched.earlier_today.map((x: ScheduleOccurrence) => buildScheduleOccurrence(x)),
        happening_now: sched.happening_now.map((x: ScheduleOccurrence) => buildScheduleOccurrence(x)),
        later_today: sched.later_today.map((x: ScheduleOccurrence) => buildScheduleOccurrence(x)),
        later_this_week: objectMap(sched.later_this_week, (v: ScheduleOccurrence[]) =>
          v.map(x => buildScheduleOccurrence(x))
        ),
        flat_list: sched.flat_list.map((x: ScheduleOccurrence) => buildScheduleOccurrence(x)),
        by_day: objectMap(sched.by_day, (v: ScheduleOccurrence[]) => v.map(x => buildScheduleOccurrence(x))),
      } as Schedule;
    })
    .catch(err => console.log(err));
};

export default function useCosmicSchedule() {
  const [schedule, setSchedule] = useState<Schedule | void>();

  const from_default = moment()
    .subtract(1, 'h')
    .subtract(15, 'm')
    .format('YYYY-MM-DD h:mm a');

  const to_default = moment()
    .add(7, 'd')
    .format('YYYY-MM-DD h:mm a');

  const fetchSchedule = useCallback((from, to) => {
    from = from || from_default;
    to = to || to_default;
    fetchData(from, to).then(s => setSchedule(s));
  }, []);

  useEffect(() => {
    fetchSchedule(from_default, to_default);
  }, []);

  return { schedule, fetchSchedule };
}
