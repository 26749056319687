import React from 'react';
import MusicInputList from './MusicInputList';
import MenuBarNoVid from '../MenuBar/MenuBarNoVid';
import ScheduleChooser from '../ScheduleChooser/ScheduleChooser';
import { useAppState } from '../../state';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import useRoomState from '../../hooks/useRoomState/useRoomState';

export default function MusicPage() {
  const roomState = useRoomState();
  const { user, getToken, isFetching } = useAppState();
  const { isConnecting, connect } = useVideoContext();

  const enterRoom = (roomName: string) => {
    getToken('music', roomName).then(token => connect(token));
  };

  return (
    <div>
      <MenuBarNoVid />
      <ScheduleChooser onChoice={enterRoom} />
      {roomState === 'connected' && <MusicInputList />}
    </div>
  );
}
