import React, { useEffect, useState } from 'react';
import { TextField, MenuItem, createStyles, makeStyles, Theme } from '@material-ui/core';
import useCosmicSchedule, { Schedule } from './useCosmicSchedule';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      minWidth: 150,
    },
  })
);

const MenuItems = (props: { schedule: Schedule | null }) => {
  if (!props.schedule) return null;
  let list = props.schedule.flat_list.slice(0, 4);
  return (
    <>
      {list.map((occ, idx) => (
        <MenuItem key={idx} value={occ.roomStr}>
          {occ.localRoomStr}
        </MenuItem>
      ))}
    </>
  );
};

export default function ScheduleChooser(props: { onChoice: (l: string) => void | undefined }) {
  const classes = useStyles();

  const { schedule } = useCosmicSchedule();
  const [roomName, setRoomName] = useState<string>('');

  useEffect(() => {
    if (!schedule) return;
    let roomStr =
      schedule.happening_now[0]?.roomStr || schedule.later_today[0]?.roomStr || schedule.flat_list[0].roomStr;
    setRoomName(roomStr);
    props.onChoice(roomStr);
  }, [schedule]);

  const handleRoomChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setRoomName(event.target.value as string);
    props.onChoice(event.target.value as string);
  };

  if (!schedule) return null;

  const textFieldProps = {
    id: 'select',
    select: true,
    InputProps: { style: { fontSize: 'min(1em,3vw)' } },
    InputLabelProps: { style: { fontSize: 'min(0.8em,2.6vw)' } },
    label: 'Choose A Class',
    className: classes.textField,
    value: roomName,
  };

  const list = schedule.flat_list.slice(0, 4);

  return (
    <TextField {...textFieldProps} onChange={handleRoomChange}>
      {list.map(occ => (
        <MenuItem key={occ.roomStr} value={occ.roomStr}>
          {occ.localRoomStr}
        </MenuItem>
      ))}
    </TextField>
  );
}
