import { TimerStateType } from '../types/FitnessTimerTypes';
import React from 'react';
import { makeStyles, Button } from '@material-ui/core';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  buttons: {
    cursor: 'pointer',
    fontSize: '1em',
    flex: 1,
  },
});

export default function FitnessTimerButtons(props: { timerState: TimerStateType; onAction: (action: string) => void }) {
  const classes = useStyles();

  return (
    <div>
      {!props.timerState.interval && (
        <Button className={classes.buttons} onClick={e => props.onAction('start')} variant="contained" color="primary">
          START
        </Button>
      )}
      {!!props.timerState.interval && (
        <Button className={classes.buttons} onClick={e => props.onAction('stop')} variant="contained" color="primary">
          PAUSE
        </Button>
      )}
      {!!props.timerState.clock && (
        <Button className={classes.buttons} onClick={e => props.onAction('reset')} variant="contained" color="primary">
          RESET
        </Button>
      )}
    </div>
  );
}
