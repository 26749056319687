import React, { ReactNode } from 'react';
//import useBrowserSupport from './useBrowserSupport';
import { createStyles, makeStyles, Theme, Dialog, Paper } from '@material-ui/core';
import DonutImg from '../../img/donut-phone.png';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      position: 'relative',
      backgroundColor: '#000000BB',
      fontSize: '3vw',
      fontWeight: 'bold',
      borderRadius: '1em',
      boxShadow: '0 0 0.5em black',
      color: 'white',
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      margin: '2em',
    },
    header: {
      display: 'flex',
      flexDirection: 'row',
    },
    img: {
      width: '4em',
      margin: '2em',
    },
    msg: {
      display: 'inline-block',
    },
    guideimg: {
      width: '100%',
      maxHeight: '50vh',
    },
  })
);

export interface PropsType {
  children: ReactNode;
}

export default function BrowserError({ msg, img }: { msg: string; img: string | null }) {
  //const { msg, img } = useBrowserSupport();
  const classes = useStyles();

  return (
    <div className={classes.paper}>
      <div className={classes.header}>
        <img className={classes.img} src={DonutImg} />
        <div className={classes.msg}>{msg}</div>
      </div>
      <div>{img && <img className="guideimg" src={img} />}</div>
    </div>
  );
}
