import { useEffect, useState, useCallback } from 'react';
import { RemoteParticipant } from 'twilio-video';
import useDominantSpeaker from '../useDominantSpeaker/useDominantSpeaker';
import useVideoContext from '../useVideoContext/useVideoContext';

const sortParticipants = function(value: RemoteParticipant[]) {
  value = value.filter(p => p.identity !== 'ig');
  value = value.filter(p => p.identity !== 'fb');
  value = value.filter(p => p.identity !== 'timer');
  const list = value.sort((a, b) => {
    let aval = !!Array.from(a.tracks.values()).find(p => p.trackName.includes('camera') && p.isTrackEnabled === true)
      ? 1
      : 0;
    let bval = !!Array.from(b.tracks.values()).find(p => p.trackName.includes('camera') && p.isTrackEnabled === true)
      ? 1
      : 0;
    console.log(`${a.identity} ${aval}`);
    console.log(`${b.identity} ${bval}`);
    return bval - aval;
  });
  console.log('Sorting Participants: ' + list.map(x => x.identity).join(','));
  return list;
};

export default function useParticipants() {
  const { room } = useVideoContext();
  const dominantSpeaker = useDominantSpeaker();
  const [participants, setParticipants] = useState(sortParticipants(Array.from(room.participants.values())));

  const triggerSort = useCallback(() => {
    setParticipants(prevParticipants => sortParticipants(prevParticipants));
  }, []);

  // When the dominant speaker changes, they are moved to the front of the participants array.
  // This means that the most recent dominant speakers will always be near the top of the
  // ParticipantStrip component.
  useEffect(() => {
    console.log('Dominant Speaker: ' + (dominantSpeaker ? dominantSpeaker.identity : 'null'));
    if (dominantSpeaker) {
      setParticipants(prevParticipants => {
        let list = prevParticipants;
        list = list.filter(p => p !== dominantSpeaker);
        return sortParticipants([dominantSpeaker, ...list]);
      });
    }
  }, [dominantSpeaker]);

  useEffect(() => {
    const participantConnected = (participant: RemoteParticipant) =>
      setParticipants(prevParticipants => sortParticipants([...prevParticipants, participant]));
    const participantDisconnected = (participant: RemoteParticipant) =>
      setParticipants(prevParticipants => sortParticipants(prevParticipants.filter(p => p !== participant)));
    room.on('participantConnected', participantConnected);
    room.on('participantDisconnected', participantDisconnected);
    return () => {
      room.off('participantConnected', participantConnected);
      room.off('participantDisconnected', participantDisconnected);
    };
  }, [room]);

  return { participants, triggerSort };
}
