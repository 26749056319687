import React from 'react';
import ReactDOM from 'react-dom';

import { CssBaseline } from '@material-ui/core';
import { MuiThemeProvider } from '@material-ui/core/styles';

import App from './App';
import AppStateProvider, { useAppState } from './state';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import { ConnectOptions } from 'twilio-video';
import { isMobile } from './utils';
import ErrorDialog from './components/ErrorDialog/ErrorDialog';

import PrivateRoute from './components/PrivateRoute/PrivateRoute';

import AdminPage from './custom/AdminPage/AdminPage';

import theme from './theme';
import './types';
import { VideoProvider } from './components/VideoProvider';
import TimerPage from './custom/FitnessTimer/TimerPage';
import { VideoProviderNoPub } from './components/VideoProvider/VideoProviderNoPub';
import LandingPage from './custom/LandingPage/LandingPage';
import useBrowserSupport from './custom/useBrowserSupport/useBrowserSupport';
import BrowserError from './custom/useBrowserSupport/BrowserError';
// @ts-ignore
import { SSEProvider } from 'react-hooks-sse';
import ClassPage from './custom/ClassDetailView/ClassPage';
import MusicPage from './custom/MusicPlayer/MusicPage';
import PurchasePage from './custom/PurchasePage/PurchasePage';
import FBView from './custom/FBView/FBView';

// See: https://media.twiliocdn.com/sdk/js/video/releases/2.0.0/docs/global.html#ConnectOptions
// for available connection options.
const connectionOptions: ConnectOptions = {
  // Bandwidth Profile, Dominant Speaker, and Network Quality
  // features are only available in Small Group or Group Rooms.
  // Please set "Room Type" to "Group" or "Small Group" in your
  // Twilio Console: https://www.twilio.com/console/video/configure
  bandwidthProfile: {
    video: {
      mode: 'collaboration',
      dominantSpeakerPriority: 'high',
      renderDimensions: {
        high: { height: 720, width: 1280 },
        standard: { height: 360, width: 640 },
        low: { height: 90, width: 160 },
      },
    },
  },
  dominantSpeaker: true,
  networkQuality: { local: 1, remote: 1 },
  logLevel: 'debug',

  // Comment this line if you are playing music.
  //maxAudioBitrate: 16000,

  // VP8 simulcast enables the media server in a Small Group or Group Room
  // to adapt your encoded video quality for each RemoteParticipant based on
  // their individual bandwidth constraints. This has no effect if you are
  // using Peer-to-Peer Rooms.
  preferredVideoCodecs: [{ codec: 'VP8', simulcast: true }],
};

// For mobile browsers, limit the maximum incoming video bitrate to 2.5 Mbps.
if (isMobile && connectionOptions?.bandwidthProfile?.video) {
  connectionOptions!.bandwidthProfile!.video!.maxSubscriptionBitrate = 2500000;
}

const VideoApp = () => {
  const { error, setError } = useAppState();
  const { msg, img } = useBrowserSupport();

  if (msg) return <BrowserError msg={msg} img={img} />;

  return (
    <VideoProvider options={connectionOptions} onError={setError}>
      <ErrorDialog dismissError={() => setError(null)} error={error} />
      <SSEProvider endpoint="https://cosmic-timer.herokuapp.com?name=test" options={null}>
        <App />
      </SSEProvider>
    </VideoProvider>
  );
};

const FbApp = () => {
  const { error, setError } = useAppState();
  const { msg, img } = useBrowserSupport();

  if (msg) return <BrowserError msg={msg} img={img} />;

  return (
    <VideoProvider options={connectionOptions} onError={setError}>
      <ErrorDialog dismissError={() => setError(null)} error={error} />
      <SSEProvider endpoint="https://cosmic-timer.herokuapp.com?name=test" options={null}>
        <FBView />
      </SSEProvider>
    </VideoProvider>
  );
};

const LandingApp = () => {
  const { error, setError } = useAppState();
  const { msg, img } = useBrowserSupport();

  if (msg) return <BrowserError msg={msg} img={img} />;

  return (
    <VideoProvider options={connectionOptions} onError={setError}>
      <ErrorDialog dismissError={() => setError(null)} error={error} />
      <SSEProvider endpoint="https://cosmic-timer.herokuapp.com?name=test" options={null}>
        <LandingPage />
      </SSEProvider>
    </VideoProvider>
  );
};

const TimerApp = () => {
  const { error, setError } = useAppState();

  return (
    <VideoProviderNoPub onError={setError}>
      <ErrorDialog dismissError={() => setError(null)} error={error} />
      <SSEProvider endpoint="https://cosmic-timer.herokuapp.com?name=test" options={null}>
        <TimerPage />
      </SSEProvider>
    </VideoProviderNoPub>
  );
};

const MusicApp = () => {
  const { error, setError } = useAppState();

  return (
    <VideoProviderNoPub onError={setError}>
      <ErrorDialog dismissError={() => setError(null)} error={error} />
      <MusicPage />
    </VideoProviderNoPub>
  );
};

const PurchaseApp = () => {
  const { error, setError } = useAppState();

  return (
    <VideoProviderNoPub onError={setError}>
      <ErrorDialog dismissError={() => setError(null)} error={error} />
      <PurchasePage />
    </VideoProviderNoPub>
  );
};

ReactDOM.render(
  <MuiThemeProvider theme={theme}>
    <CssBaseline />
    <Router>
      <QueryParamProvider ReactRouterRoute={Route}>
        <AppStateProvider>
          <Switch>
            <PrivateRoute exact path="/">
              <LandingApp />
            </PrivateRoute>
            <PrivateRoute path="/room/:URLRoomName">
              <LandingApp />
            </PrivateRoute>
            <Route path="/fb">
              <FbApp />
            </Route>
            <Route path="/legacy">
              <VideoApp />
            </Route>
            <Route path="/timer">
              <TimerApp />
            </Route>
            <Route path="/admin">
              <AdminPage />
            </Route>
            <Route path="/music">
              <MusicApp />
            </Route>
            <Route path="/class">
              <ClassPage />
            </Route>
            <Route path="/purchase">
              <PurchaseApp />
            </Route>
            <Redirect to="/" />
          </Switch>
        </AppStateProvider>
      </QueryParamProvider>
    </Router>
  </MuiThemeProvider>,
  document.getElementById('root')
);
