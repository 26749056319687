import AudioTrack from '../../components/AudioTrack/AudioTrack';
import React from 'react';

import { AudioTrack as IAudioTrack } from 'twilio-video';
import useRemoteMusicParticipant from './hooks/useRemoteMusicParticipant';
import usePublications from '../../hooks/usePublications/usePublications';
import useTrack from '../../hooks/useTrack/useTrack';

export default function MusicPopup() {
  const participant = useRemoteMusicParticipant();
  const publications = usePublications(participant);

  let pub = publications.find(x => x.kind === 'audio');

  const track = useTrack(pub);

  return <AudioTrack track={track as IAudioTrack} participant={participant} />;
}
