import React, { useEffect } from 'react';
import { makeStyles, Button } from '@material-ui/core';
import FitnessTimerDisplay from './components/FitnessTimerDisplay';
import FitnessTimerProps from './components/FitnessTimerProps';
import useTimerRemoteState from './hooks/useTimerRemoteState';
import { useTimerLogic } from './hooks/useTimerLogic';
import FitnessTimerButtons from './components/FitnessTimerButtons';
import theme from '../../theme';
import useTimerBroadcast from './hooks/useTimerBroadcast';
import useTimerDisplayLogic from './hooks/useTimerDisplayLogic';
import AccessTimeIcon from '@material-ui/icons/AccessTime';

const useStyles = makeStyles({
  container: {
    backgroundColor: theme.palette.background.default,
    maxWidth: '40em',
    margin: '5em auto',
    padding: '2em',
    position: 'relative',
  },
  buttons: {
    cursor: 'pointer',
  },
  dispContainer: {
    fontSize: '3.5em',
    padding: '1em 0',
  },
  buttonContainer: {
    fontSize: '2em',
  },
  button: {
    margin: theme.spacing(1),
  },
});

const SimpleTimerButton = ({
  time,
  classes,
}: {
  time: number;
  classes: Record<'container' | 'button' | 'buttons' | 'dispContainer' | 'buttonContainer', string>;
}) => {
  return (
    <Button
      variant="contained"
      color="secondary"
      className={classes.button}
      startIcon={<AccessTimeIcon />}
      onClick={() => {
        fetch(`https://cosmic-timer.herokuapp.com/start?time=${time}`);
      }}
    >
      {time}
    </Button>
  );
};

export default function FitnessTimer() {
  const { remoteTimerState } = useTimerRemoteState();
  const { timerProps, timerState, timerSetProp, timerStart, timerStop, timerReset } = useTimerLogic();
  const { startTimerBroadcast, stopTimerBroadcast, sendTimerDispl } = useTimerBroadcast();
  const { timerDispl, setTimerDispl } = useTimerDisplayLogic({ timerProps: timerProps, timerState: timerState });

  const classes = useStyles();

  useEffect(() => {
    if (!remoteTimerState) return;
    setTimerDispl(remoteTimerState);
  }, [remoteTimerState]);

  useEffect(() => {
    timerState.interval ? startTimerBroadcast() : stopTimerBroadcast();
  }, [timerState.interval]);

  useEffect(() => {
    sendTimerDispl(timerDispl);
  }, [timerDispl]);

  const onAction = (action: string) => {
    (({ start: timerStart, stop: timerStop, reset: timerReset } as { [key: string]: () => void })[action]());
  };

  const simpleTimer = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    let elem = e.target as HTMLButtonElement;
    let time = elem.dataset.seconds;
    fetch(`https://cosmic-timer.herokuapp.com/start?time=${time}`);
  };

  return (
    <div>
      <div className={classes.container}>
        <div>
          <h3>Simple Timers</h3>
          <SimpleTimerButton classes={classes} time={15} />
          <SimpleTimerButton classes={classes} time={20} />
          <SimpleTimerButton classes={classes} time={30} />
          <SimpleTimerButton classes={classes} time={45} />
          <SimpleTimerButton classes={classes} time={60} />
          <SimpleTimerButton classes={classes} time={65} />
          <SimpleTimerButton classes={classes} time={75} />
          <SimpleTimerButton classes={classes} time={90} />
          <Button
            variant="contained"
            color="secondary"
            className={classes.button}
            startIcon={<AccessTimeIcon />}
            onClick={() => {
              fetch(`https://cosmic-timer.herokuapp.com/stop`);
            }}
          >
            Stop
          </Button>
        </div>
      </div>
      <div className={classes.container}>
        <h3>Tabata Timer</h3>
        <div>
          <FitnessTimerProps timerProps={timerProps} onChange={timerSetProp} />
        </div>
        <div className={classes.dispContainer}>
          <FitnessTimerDisplay {...timerDispl} />
        </div>
        <div className={classes.buttonContainer}>
          <FitnessTimerButtons timerState={timerState} onAction={onAction} />
        </div>
      </div>
    </div>
  );
}
