import { useEffect, useRef } from 'react';
import { AudioTrack as IAudioTrack, Participant } from 'twilio-video';
import { useAppState } from '../../state';

interface AudioTrackProps {
  track: IAudioTrack;
  participant: Participant | null | undefined;
}

export default function AudioTrack({ track, participant }: AudioTrackProps) {
  const { activeSinkId } = useAppState();
  const audioEl = useRef<HTMLAudioElement>();

  useEffect(() => {
    if (!track) return;
    console.log('Rendering Audio Track: ' + track.name);
    audioEl.current = track.attach();
    audioEl.current.setAttribute('data-cy-audio-track-name', track.name);
    audioEl.current.setAttribute('data-participant', participant ? participant.identity : '');
    var span = document.createElement('span');
    var div = document.createElement('div');
    span.className = 'audioContainer';
    span.style.display = 'none';
    div.innerHTML = participant?.identity || '???';
    span.appendChild(div);
    span.appendChild(audioEl.current);
    document.body.appendChild(span);
    return () => track.detach().forEach(el => el.remove());
  }, [track]);

  useEffect(() => {
    audioEl.current?.setSinkId?.(activeSinkId);
  }, [activeSinkId]);

  return null;
}
