import React, { useState, useEffect } from 'react';
import { List, ListItem, ListItemText, TextField, Button } from '@material-ui/core';
import ParticipantList from '../ParticipantList/ParticipantList';

//import useTwilioAPI from '../../../hooks/useTwilioAPI/useTwilioAPI';

export default function RoomList() {
  const [rooms, setRooms] = useState<any[]>([]);
  const [roomName, setRoomName] = useState('');

  console.log('rendering...');
  console.log(rooms);

  const fetchRooms = async () => {
    const resp = await fetch('/twilio/room_list');
    const r = await resp.json();
    setRooms(r);
  };

  const createRoom = async () => {
    await fetch(`/twilio/create_room?name=${roomName}`);
    fetchRooms();
  };

  useEffect(() => {
    fetchRooms();
  }, []);

  return (
    <div>
      <div>
        <TextField onChange={e => setRoomName(e.target.value)} label="New Room Name" />
        <Button onClick={createRoom}>Create</Button>
      </div>
      <List>
        {rooms.map(room => (
          <>
            <ListItem>
              <ListItemText primary={room.uniqueName} />
            </ListItem>
            <ParticipantList roomsid={room.sid} />
          </>
        ))}
      </List>
    </div>
  );
}
