import React, { useEffect, useCallback, useState } from 'react';
import { useAppState } from '../../../state';
import { CosmicUserType } from '../../../state/useCosmicAuth/useCosmicAuth';
import { makeStyles, createStyles, Theme } from '@material-ui/core';
import { PaymentMethod } from '@stripe/stripe-js';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: '25em',
      padding: '1em',
      boxShadow: '0 0 0.5em black inset',
      borderRadius: '1em 0 0 1em',
      background: 'white',
      display: 'flex',
      '& span': {
        color: 'black',
        margin: '0 1em',
      },
    },
    button: {
      border: 'none',
      boxShadow: '0 0 0.5em black inset',
      cursor: 'pointer',
      width: '8em',
    },
    btn1: {
      backgroundColor: '#CCEECC',
    },
    btn2: {
      backgroundColor: '#EECCCC',
      borderRadius: '0 1em 1em 0',
    },
    flex: {
      display: 'flex',
      marginBottom: '0.5em',
    },
    wrapper: {
      padding: '0.5em',
    },
  })
);

export interface PaymentSource {
  id: string;
  object: string;
  address_city: string | null;
  address_country: string | null;
  address_line1: string | null;
  address_line1_check: string | null;
  address_line2: string | null;
  address_state: string | null;
  address_zip: string | null;
  address_zip_check: string | null;
  brand: string;
  country: string;
  customer: string;
  cvc_check: string | null;
  dynamic_last4: string | null;
  exp_month: number;
  exp_year: number;
  fingerprint: string;
  funding: string;
  last4: string;
  metadata: {};
  name: string;
  tokenization_method: string | null;
}

const getCustySources = (id: number) => {
  return fetch(`https://cosmicfitclub.com/models/customers/${id}/payment_sources`).then(resp => resp.json());
};

const remSource = (custy_id: number, source_id: String) => {
  return fetch('https://cosmicfitclub.com/checkout/remove_card', {
    method: 'POST',
    mode: 'cors',
    credentials: 'include',
    body: JSON.stringify({ customer_id: custy_id, source_id: source_id }),
    headers: new Headers({ 'Content-type': 'application/json' }),
  });
};

export default function SavedUserCards(attrib: {
  onCard: (src: PaymentMethod | PaymentSource, save: boolean | undefined) => void;
  onBusy: (value: boolean) => void;
  refresh: boolean;
}) {
  const { user } = useAppState();
  const [sources, setSources] = useState<PaymentSource[]>([]);
  const classes = useStyles();

  const updateSources = useCallback(() => {
    if (!user) return;
    let id = (user as CosmicUserType).customer_id;
    getCustySources(id).then(val => {
      setSources(val as PaymentSource[]);
      attrib.onBusy(false);
    });
  }, [setSources, user]);

  const removeSavedSource = useCallback(
    (source: PaymentSource) => {
      let custy_id = (user as CosmicUserType).customer_id;
      attrib.onBusy(true);
      remSource(custy_id, source.id).then(resp => updateSources());
    },
    [user]
  );

  useEffect(() => {
    updateSources();
  }, [user, attrib.refresh]);

  return (
    <div className={classes.wrapper}>
      {sources.length > 0 && <h3>Your Saved Cards:</h3>}
      {sources.map(source => (
        <div className={classes.flex}>
          <div className={classes.container}>
            <span>{source.brand || 'xxxx'}</span>
            <span>{source.last4 || 'xxxx'}</span>
          </div>
          <button className={classes.button + ' ' + classes.btn1} onClick={() => attrib.onCard(source, false)}>
            Use Card
          </button>
          <button className={classes.button + ' ' + classes.btn2} onClick={() => removeSavedSource(source)}>
            Remove
          </button>
        </div>
      ))}
    </div>
  );
}
